/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';

import { DataTable, Button } from 'carbon-components-react';
import { Delete16 } from '@carbon/icons-react';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const headers = [
  {
    // `key` is the name of the field on the row object itself for the header
    key: 'datasetname',
    // `header` will be the name you want rendered in the Table Header
    header: 'Data sets name',
  },
  {
    key: 'mainframe',
    header: 'Mainframe hardware',
  },
  {
    key: 'zOSversion',
    header: 'z/OS Version',
  },
  {
    key: 'numberModules',
    header: 'Number of modules',
  },
  {
    key: 'selectedModuleButton',
    header: '',
  },
  {
    key: 'deleteIcon',
    header: '',
  },
];

class AvailableDatasetTable extends React.Component {
  //Click Event Handler when the user select or un-selected data set
  poolSelectedHandler = row => {
    this.props.getSelectedRow(row);
  };

  render() {
    const rows = [];

    //showSelectModuleTableButton

    if (this.props.filenames && this.props.filenames.length > 0) {
      this.props.filenames.map((file, index) => {
        //console.log('file:' + file.name + ' index ' + index);
        if (file.available && file.moduleListAvailable && !file.errors) {
          rows.push({
            //isSelected: file.isSelected,
            id: index,
            datasetname: file.name,
            mainframe: 'Not selected',
            zOSversion: 'Not selected',
            numberModules: file.numberSelected + '/' + file.totalNumberModule,
            selectedModuleButton: (
              <Button
                kind="tertiary"
                onClick={() => {
                  this.props.showSelectModuleTableButton(file);
                }}>
                Select & optimize
              </Button>
            ),
            deleteIcon: (
              <Button
                className="some-class"
                disabled={false}
                hasIconOnly
                iconDescription="delete data set"
                kind="ghost"
                onClick={() => this.props.showDeleteModalTrigger(file.name)}
                renderIcon={Delete16}
                size="default"
                tabIndex={0}
                tooltipAlignment="center"
                tooltipPosition="bottom"
                type="button"
              />
            ),
          });
        }
      });
    }

    //debugger;
    return (
      <DataTable
        rows={rows}
        headers={headers}
        render={({ rows, headers, getHeaderProps }) => (
          <TableContainer>
            <Table size="normal">
              <TableHead>
                <TableRow>
                  {headers.map(header => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody id="availableTable">
                {rows.length > 0 &&
                  rows.map(row => (
                    <TableRow key={row.id}>
                      {row.cells.map(cell => (
                        <TableCell key={cell.id}>
                          {cell.id.includes('numberModules') ? (
                            <div style={{ fontWeight: 'bold' }}>
                              {cell.value} Selected
                            </div>
                          ) : (
                            cell.value
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                {rows.length === 0 && (
                  <TableRow key="0">
                    <TableCell key="0" colSpan="7">
                      <p className="paragraph_styles_no_item">
                        There are no data sets available to be optimized
                      </p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
    );
  }
}

export default AvailableDatasetTable;
