import React from 'react';
import { Modal } from 'carbon-components-react';

export const IdleTimeOutModal = ({
  showModal,
  handleClose,
  handleLogout,
  remainingTime,
}) => {
  return (
    <Modal
      className="IconDeleteModal"
      danger
      hasScrollingContent={false}
      iconDescription="Close the modal"
      modalAriaLabel="You are being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you will be logged off in 1 minute."
      modalHeading="Session Timeout"
      modalLabel=""
      onBlur={function noRefCheck() {}}
      onClick={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      onRequestClose={handleClose}
      onRequestSubmit={handleLogout}
      onSecondarySubmit={handleClose}
      open={showModal}
      passiveModal={false}
      primaryButtonDisabled={false}
      primaryButtonText="Logout"
      secondaryButtonText="Stay"
      selectorPrimaryFocus="[data-modal-primary-focus]"
      size={undefined}>
      <p className="bx--modal-content_text_timeout_modal">
        The current session is about to expire in{' '}
        <span style={{ color: '#FF0000' }}>{remainingTime}</span> seconds. Would
        you like to continue the session?
      </p>
    </Modal>
  );
};
