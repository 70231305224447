/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OptimizeDataset from './OptimizeDataset';
import SelectModuleTable from './SelectModulesTable';
import { Loading } from 'carbon-components-react';
import * as actions from 'actions';
import axios from 'axios';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from './IdleTimeOutModal';

let countdownInterval;
let timeout;

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();

    this.state = {
      filenames: [],
      fileArray: [],
      fileUploadInformation: [],
      fileArrayOptimized: [],
      fileArrayHistory: [],
      fileArrayNotifications: [],
      selectedSingleFileAvailableDataset: [],
      selectedSingleFileOptimizedDataset: [],
      fileUploadStatus: 'edit',
      headerTab: 0,
      showCompletedNotification: false,
      showErrorNotification: false,
      showDeleteModal: false,
      errorMessage: '',
      showDeletedNotificationAvailableDataset: false,
      showDeletedNotificationOptimizedDataset: false,
      showOptimizingNotificationAvailableDataset: false,
      showOptimizedCompletedNotification: false,
      optimizingDatasetName: '',
      id: '',
      token: '',
      name: '',
      periodicFetch: true,
      datasetTableTab: 0,
      showSelectModuleTable: false,
      selectedModuleTableData: {},
      currentDataset: {},
      lastOpitmizedDatasetName: '',
      lastOpitmizedSuccessNumber: 0,
      disableOpitmizationDueToExpiry: false,
      uploadDuplicatedName: false,
      uploadDuplicatedNameList: [],
      completeUploadDatasetName: '',
      currentSelectDatasetName: '',
      showDeleteModalOptimizedDataset: false,
      currentSelectOptimizedDatasetName: {},
      optimizingTimeOut: false,
      role: '',
      isIBMer: false,
      timeOutSession: false,
      timeout: 1000 * 60 * 25,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      timeoutCountdown: 0,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  //functions for handling session timeout
  //----------------------------------------------------

  clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    if (!this.state.showModal) {
      this.clearSessionInterval();
      this.clearSessionTimeout();
    }
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const delay = 1000 * 1 * 1;
    if (!this.state.showModal) {
      timeout = setTimeout(() => {
        let countDown = 60;
        this.setState({ showModal: true, timeoutCountdown: countDown });
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            this.setState({ timeoutCountdown: --countDown });
          } else {
            //this.props.history.push('/login');

            this.props.history.push({
              pathname: '/login',
              state: {
                showModal: true,
              },
            });
          }
        }, 1000);
      }, delay);
    }
  }

  handleClose() {
    this.clearSessionInterval();
    this.clearSessionTimeout();
    this.setState({ showModal: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.clearSessionInterval();
    this.clearSessionTimeout();
    //this.props.history.push('/login');
    window.location.href = '/appid/logout';
  }
  //----------------------------------------------------

  /*
  when the user select the files from their folder
  update the files information in the array
  */
  handleChangeSelectFileForUpload = async files => {
    await this.setState({
      fileUploadInformation: this.state.fileUploadInformation.concat(
        Array.prototype.map.call(files, file => file)
      ),
    });
  };

  //delete the selected files from the list
  //when the user click the Closed icon
  //in the FileUploader component
  handleDelectSelectFileForUpload = async filteredArray => {
    if (Array.isArray(filteredArray)) {
      await this.setState({
        fileUploadInformation: this.state.fileUploadInformation.filter(el =>
          filteredArray.includes(el.name)
        ),
      });
    }
  };

  validateFileUpload = async () => {
    //remove duplicated names
    const uniqueSetArray = await [
      ...new Set(
        Array.prototype.map.call(
          this.state.fileUploadInformation,
          file => file.name
        )
      ),
    ];

    let sameName = false;
    let exceedMaxSize = false;
    let errorMessage;

    let size = 0;
    this.state.fileUploadInformation.forEach(item => {
      size += item.size;
    });

    if (size > 20000000) {
      exceedMaxSize = true;
    }

    if (this.state.fileUploadInformation.length !== uniqueSetArray.length) {
      sameName = true;
    }

    const currentSetArray = await [
      ...new Set(
        Array.prototype.map.call(this.state.fileArray, file => file.name)
      ),
    ];

    let intersection = currentSetArray.filter(x => uniqueSetArray.includes(x));

    //check for correct extension of the file names
    const inCorretFormat = uniqueSetArray.some(
      item => !item.endsWith('.XMIT.TRS')
    );

    const format = /^([a-zA-Z0-9@#.-]{0,44})$/;
    //check for correct extension of the file names
    const correctFormat = uniqueSetArray.every(item => format.test(item));

    const maxReach =
      uniqueSetArray.length + this.state.fileArray.length > 20 ? true : false;
    if (sameName) {
      errorMessage = 'Duplicated file names are not allowed';
    } else if (!correctFormat) {
      errorMessage = 'File name does not have the correct format';
    } else if (inCorretFormat) {
      errorMessage = 'Only .XMIT.TRS files are allowed';
    } else if (maxReach) {
      errorMessage = 'Maximum of 20 data sets are allowed';
    } else if (exceedMaxSize) {
      errorMessage = 'Maximum file size of 20MB allowed per upload';
    }

    if (
      inCorretFormat ||
      !correctFormat ||
      maxReach ||
      sameName ||
      exceedMaxSize
    ) {
      this.setState({
        showErrorNotification: true,
        showCompletedNotification: true,
        errorMessage: errorMessage,
        fileUploadInformation: [],
      });
    } else if (intersection.length > 0) {
      this.setState({
        uploadDuplicatedName: true,
        uploadDuplicatedNameList: intersection,
      });
    } else {
      this.handleUploadFileSubmitButton();
    }
  };

  /*
handleSubmitButton is called when the Upload File submit button is clicked
*/

  handleUploadFileSubmitButton = async () => {
    //remove duplicated names
    const uniqueSetArray = await [
      ...new Set(
        Array.prototype.map.call(
          this.state.fileUploadInformation,
          file => file.name
        )
      ),
    ];

    const fileArray = await uniqueSetArray.map(file => {
      const container = {};
      container.name = file;
      container.hardware = '';
      container.software = '';
      container.available = true;
      container.optimizing = false;
      container.optimized = false;
      container.inQueue = true;
      container.modules = [];
      container.totalNumberModule = container.modules.length;
      container.numberSelected = container.modules.length;
      container.moduleListAvailable = false;
      container.errors = false;
      container.status = '';
      container.fileUploadStatus = 'uploading';
      container.showCompletedNotification = false;
      container.showOptimizedCompletedNotification = false;

      return container;
    });

    //disable all the completion notification after the user click the upload
    const newDataset = await this.state.fileArray.map(dataset => {
      dataset.showCompletedNotification = false;

      return dataset;
    });

    //overwrite the dataset that has the same name
    await fileArray.forEach(m => {
      const item = newDataset.find(n => n.name === m.name);
      if (item) {
        return Object.assign(item, m);
      }
      newDataset.push(m);
    });

    //fileArray: this.state.fileArray.filter(file => file.name !== filename)

    await this.setState({
      fileUploadStatus: 'uploading',
      showCompletedNotification: false,
      fileArray: newDataset.filter(file => !file.errors),
      filenames: [],
      selectedTab: 0,
      uploadDuplicatedName: false,
      uploadDuplicatedNameList: [],
    });

    //dispatch upload action to upload the files to the object storage
    actions.uploadFile(this.state.fileUploadInformation).then(
      () => {
        actions.uploadDataset([this.props.id, this.state.fileArray]).then(
          () => {
            let periodicFetch = false;
            let showOptimizedCompletedNotification = false;
            let showOptimizingNotificationAvailableDataset = false;
            let showCompletedNotification = false;
            let showErrorNotification = false;
            let errorMessage = '';
            let someUploading = false;
            let someOpitmizing = false;
            let disableOpitmizationDueToExpiry = false;
            let completeUploadDatasetName = '';
            let optimizingTimeOut = false;

            //clear the previous timer
            clearInterval(this.timer);
            this.timer = null;

            this.timer = setInterval(async () => {
              await this.props.dispatch(actions.checkAuth());

              //fetch data sets from the Cloudant database
              if (this.props.id) {
                await this.props.dispatch(actions.fetchDatasets(this.props.id));

                const datasets =
                  typeof this.props.datasets === 'undefined'
                    ? []
                    : this.props.datasets;

                const optimizedDatasets =
                  typeof this.props.optimizedDatasets === 'undefined'
                    ? []
                    : this.props.optimizedDatasets;

                const fileArrayHistory =
                  typeof this.props.fileArrayHistory === 'undefined'
                    ? []
                    : this.props.fileArrayHistory;

                const expiryDate =
                  typeof this.props.expiryDateFormat === 'undefined'
                    ? ''
                    : this.props.expiryDateFormat;

                const isIBMer = this.props.isIBMer;

                disableOpitmizationDueToExpiry = this.hasExpired(
                  expiryDate,
                  isIBMer
                );

                if (datasets.length > 0 || optimizedDatasets.length > 0) {
                  someUploading = datasets.some(
                    item => item.fileUploadStatus === 'uploading'
                  );
                  someOpitmizing = optimizedDatasets.some(
                    item => item.optimizing === true
                  );
                  periodicFetch = someUploading || someOpitmizing;

                  //if there is any errors on the uploaded dataset
                  //handle errors
                  showErrorNotification = datasets.some(item => {
                    if (item.errors) errorMessage = item.status;
                    return item.errors;
                  });

                  optimizingTimeOut = optimizedDatasets.some(
                    item => item.errors === true
                  );

                  let obj = datasets.find(o => o.showCompletedNotification);

                  if (obj) {
                    completeUploadDatasetName = obj.name;
                    showCompletedNotification = true;
                  } else {
                    showCompletedNotification = false;
                  }

                  showOptimizedCompletedNotification = optimizedDatasets.some(
                    item => item.showOptimizedCompletedNotification === true
                  );

                  if (this.props.optimizingDatasetName)
                    showOptimizingNotificationAvailableDataset = true;
                }

                if (!periodicFetch) {
                  clearInterval(this.timer);
                  this.timer = null;
                }

                //update file setState
                this.setState({
                  fileUploadStatus: someUploading
                    ? this.state.fileUploadStatus
                    : 'edit',
                  fileUploadInformation: someUploading
                    ? this.state.fileUploadInformation
                    : [],
                  fileArray: datasets,
                  fileArrayOptimized: optimizedDatasets,
                  fileArrayHistory: fileArrayHistory,
                  token: this.props.token,
                  id: this.props.id,
                  name: this.props.name,
                  showCompletedNotification: showCompletedNotification,
                  showOptimizedCompletedNotification: showOptimizedCompletedNotification,
                  showOptimizingNotificationAvailableDataset: showOptimizingNotificationAvailableDataset,
                  showErrorNotification: showErrorNotification,
                  errorMessage: errorMessage,
                  lastOpitmizedDatasetName: this.props.lastOpitmizedDatasetName,
                  optimizingDatasetName: this.props.optimizingDatasetName,
                  disableOpitmizationDueToExpiry: disableOpitmizationDueToExpiry,
                  completeUploadDatasetName: completeUploadDatasetName,
                  optimizingTimeOut: optimizingTimeOut,
                  timeOutSession: false,
                });
              } else {
                this.props.history.push('/login');
              }
            }, 1000 * 60);
          },
          error => {
            //console.log('error 1: ' + error);

            this.setState({
              fileUploadInformation: [],
              fileArray: this.state.fileArray.filter(
                file => file.fileUploadStatus !== 'uploading'
              ),
              showCompletedNotification: true,
              fileUploadStatus: 'edit',
              showErrorNotification: true,
              errorMessage:
                'Your session has expired, please refresh the page and try again',
              filenames: [],
              timeOutSession: true,
            });

            this.props.history.push('/login');
          }
        );
      },
      error => {
        //console.log('error 2: ' + error);

        this.setState({
          fileUploadInformation: [],
          fileArray: this.state.fileArray.filter(
            file => file.fileUploadStatus !== 'uploading'
          ),
          showCompletedNotification: true,
          fileUploadStatus: 'edit',
          showErrorNotification: true,
          errorMessage:
            'Your session has expired, please refresh the page and try again',
          filenames: [],
          timeOutSession: true,
        });

        this.props.history.push('/login');
      }
    );
  };

  //deleteSelectedRow in available data set table
  deleteSelectedRowAvailableDataset = async filename => {
    await this.setState({
      fileArray: this.state.fileArray.filter(file => file.name !== filename),
      showDeletedNotificationAvailableDataset: true,
      selectedSingleFileAvailableDataset: [filename],
      showDeleteModal: false,
      currentSelectDatasetName: '',
    });

    /*
    Update in Cloudant database
    */

    actions.updateAvailableDataset([this.props.id, this.state.fileArray]).then(
      () => { },
      () => {
        //this.props.history.push('/login');
        this.setState({
          showSelectModuleTable: false,
          headerTab: 1,
          datasetTableTab: 0,
          showOptimizedCompletedNotification: true,
          timeOutSession: true,
        });
      }
    );

    /*
      delete in Cloud Object storage
      */
    /*
    actions.deleteFile([filename]).then(
      datasets => {
        //this.setState({ redirect: true });
        console.log(
          'deleteSelectedRowAvailableDataset datatset delete successful:'
        );
      },
      errors => {
        //this.setState({ errors: errors });

        console.log(
          'deleteSelectedRowAvailableDataset datatset delete fail:',
          errors
        );
      }
    );
    */
  };

  ////deleteSelectedRow in optimized data set table
  deleteSelectedRowOptimizedDataset = async file => {
    await this.setState({
      fileArrayOptimized: this.state.fileArrayOptimized.filter(
        item => item.id !== file.id
      ),
      showDeletedNotificationOptimizedDataset: true,
      selectedSingleFileOptimizedDataset: [file.name],
      showDeleteModalOptimizedDataset: false,
      currentSelectOptimizedDatasetName: {},
    });

    /*
    Update in Cloudant database

    */

    actions
      .updateOpitmizedDataset([this.props.id, this.state.fileArrayOptimized])
      .then(
        () => { },
        () => {
          //this.props.history.push('/login');
          this.setState({
            showSelectModuleTable: false,
            headerTab: 1,
            datasetTableTab: 1,
            showOptimizedCompletedNotification: true,
            timeOutSession: true,
          });
        }
      );

    //BOZ.LOAD.XMIT.TRS.zip
    //const optimizedDatasetName = 'BOZ.' + filename + '.zip';
    //const optimizedLogName = filename + '.summary.txt';
    /*
      delete in Cloud Object storage
      */

    actions.deleteFile([file.downloadFileName]).then(
      () => { },
      () => {
        //this.props.history.push('/login');
      }
    );

    actions.deleteFile([file.summaryFileName]).then(
      () => { },
      () => {
        //this.props.history.push('/login');
      }
    );
  };

  /*
modulesSelectedOnSelectModuleTable is called to get the total number modules that are selected
and update the select module state
inside the SelectModulesTable component
*/

  modulesSelectedOnSelectModuleTable = (length, name, itemsUpdate) => {
    // 1. Make a shallow copy of the items
    let items = [...this.state.fileArray];

    //items.find(item => item.name === name).modules = itemsUpdate;

    // 2. Make a shallow copy of the item you want to mutate
    let item = items.find(file => file.name === name);
    let foundIndex = items.findIndex(file => file.name === name);

    // 3. Replace the property you're intested in
    item.numberSelected = length;
    item.modules = itemsUpdate;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[foundIndex] = item;

    // 5. Set the state to our new copy
    this.setState({ fileArray: items });
  };

  showDeletedNotificationCloseButtonAvailableDataset = () => {
    this.setState({
      showDeletedNotificationAvailableDataset: false,
      selectedSingleFileAvailableDataset: [],
    });
  };

  showDeletedNotificationCloseButtonOptimizedDataset = () => {
    this.setState({
      showDeletedNotificationOptimizedDataset: false,
      selectedSingleFileOptimizedDataset: [],
    });
  };

  showOptimizingNotificationCloseButton = () => {
    this.setState({
      showOptimizingNotificationAvailableDataset: false,
    });
  };

  showOpitimizedCompleteNotificationCloseButton = async () => {
    const newOpitmizedDataset = await this.state.fileArrayOptimized.map(
      dataset => {
        dataset.showOptimizedCompletedNotification = false;

        return dataset;
      }
    );

    await this.setState({
      fileArrayOptimized: newOpitmizedDataset,
      showOptimizedCompletedNotification: false,
    });

    /*
  Update in Cloudant database
*/

    actions
      .updateOpitmizedDataset([this.props.id, this.state.fileArrayOptimized])
      .then(
        () => { },
        () => {
          //this.props.history.push('/login');
        }
      );
  };

  showOpitimizedCompleteNotificationTimeOutCloseButton = async () => {
    const newOpitmizedDataset = await this.state.fileArrayOptimized.map(
      dataset => {
        dataset.showOptimizedCompletedNotification = false;
        dataset.errors = false;
        return dataset;
      }
    );

    await this.setState({
      fileArrayOptimized: newOpitmizedDataset,
      showOptimizedCompletedNotification: false,
    });

    /*
  Update in Cloudant database
*/

    actions
      .updateOpitmizedDataset([this.props.id, this.state.fileArrayOptimized])
      .then(
        () => { },
        () => { }
      );
  };

  goToOptimizerHandler = () => {
    this.setState({ headerTab: 1 });
  };

  showSelectModuleTableButton = file => {
    this.setState({
      showSelectModuleTable: true,
      currentDataset: file,
    });
  };

  showSelectModuleTableButtonDisable = () => {
    this.setState({
      showSelectModuleTable: false,
      currentDataset: {},
      headerTab: 1,
      datasetTableTab: 0,
    });
  };

  /*
clickOptimizeButton is called when the Optimize button is click in the selected module Table

this function would move the data set into the OptimizeDatasetTable
*/

  clickOptimizeButton = async data => {
    const temDataName = data.name;

    let id = data.id;
    let showOptimizedCompletedNotification = false;
    let showOptimizingNotificationAvailableDataset = false;

    while (this.state.fileArrayOptimized.some(e => e.id === id)) {
      id = id + 1;
    }

    data.id = id;

    const DeepCopyData = JSON.parse(JSON.stringify(data));
    const newCopyData = [DeepCopyData].concat(this.state.fileArrayOptimized);
    const newOptimizingDatasetName =
      this.state.optimizingDatasetName === ''
        ? temDataName
        : this.state.optimizingDatasetName;

    actions
      .opitmizeDataset([
        this.props.id,
        newCopyData,
        temDataName,
        id,
        newOptimizingDatasetName,
      ])
      .then(
        async () => {
          await this.setState({
            fileArrayOptimized: newCopyData,
            headerTab: 1,
            showOptimizingNotificationAvailableDataset: true,
            optimizingDatasetName: newOptimizingDatasetName,
            lastOpitmizedDatasetName: temDataName,
            showSelectModuleTable: false,
            selectedModuleTableData: data,
            datasetTableTab: 1,
            timeOutSession: false,
          });

          //perioidic fetch after user click the optimize button
          let periodicFetch = false;
          let someUploading = false;
          let someOpitmizing = false;
          let showErrorNotification = false;
          let showCompletedNotification = false;
          let errorMessage = '';
          let disableOpitmizationDueToExpiry = false;
          let completeUploadDatasetName = '';
          let optimizingTimeOut = false;

          //clear the previous timer
          clearInterval(this.timer);
          this.timer = null;

          this.timer = setInterval(async () => {
            await this.props.dispatch(actions.checkAuth());

            //fetch data sets from the Cloudant database
            if (this.props.id) {
              await this.props.dispatch(actions.fetchDatasets(this.props.id));

              const datasets =
                typeof this.props.datasets === 'undefined'
                  ? []
                  : this.props.datasets;

              const optimizedDatasets =
                typeof this.props.optimizedDatasets === 'undefined'
                  ? []
                  : this.props.optimizedDatasets;

              const fileArrayHistory =
                typeof this.props.fileArrayHistory === 'undefined'
                  ? []
                  : this.props.fileArrayHistory;

              const expiryDate =
                typeof this.props.expiryDateFormat === 'undefined'
                  ? ''
                  : this.props.expiryDateFormat;

              const isIBMer = this.props.isIBMer;

              disableOpitmizationDueToExpiry = this.hasExpired(
                expiryDate,
                isIBMer
              );

              /*
              check if there is any dataset that has a status of uploading
              if so need to fetch dataset periodically
              */

              if (datasets.length > 0 || optimizedDatasets.length > 0) {
                someUploading = datasets.some(
                  item => item.fileUploadStatus === 'uploading'
                );
                someOpitmizing = optimizedDatasets.some(
                  item => item.optimizing === true
                );
                periodicFetch = someUploading || someOpitmizing;

                //if there is any errors on the uploaded dataset
                //handle errors
                showErrorNotification = datasets.some(item => {
                  if (item.errors) errorMessage = item.status;
                  return item.errors;
                });

                optimizingTimeOut = optimizedDatasets.some(
                  item => item.errors === true
                );

                let obj = datasets.find(o => o.showCompletedNotification);

                if (obj) {
                  completeUploadDatasetName = obj.name;
                  showCompletedNotification = true;
                } else {
                  showCompletedNotification = false;
                }

                showOptimizedCompletedNotification = optimizedDatasets.some(
                  item => item.showOptimizedCompletedNotification === true
                );

                if (this.props.optimizingDatasetName)
                  showOptimizingNotificationAvailableDataset = true;
                else showOptimizingNotificationAvailableDataset = false;
              }

              if (!periodicFetch) {
                clearInterval(this.timer);
                this.timer = null;
              }

              //opitmize button setState
              await this.setState({
                fileUploadStatus: someUploading
                  ? this.state.fileUploadStatus
                  : 'edit',
                fileUploadInformation: someUploading
                  ? this.state.fileUploadInformation
                  : [],
                fileArray: datasets,
                fileArrayOptimized: optimizedDatasets,
                fileArrayHistory: fileArrayHistory,
                token: this.props.token,
                id: this.props.id,
                name: this.props.name,
                showCompletedNotification: showCompletedNotification,
                showOptimizedCompletedNotification: showOptimizedCompletedNotification,
                showOptimizingNotificationAvailableDataset: showOptimizingNotificationAvailableDataset,
                showErrorNotification: showErrorNotification,
                errorMessage: errorMessage,
                lastOpitmizedDatasetName: this.props.lastOpitmizedDatasetName,
                optimizingDatasetName: this.props.optimizingDatasetName,
                disableOpitmizationDueToExpiry: disableOpitmizationDueToExpiry,
                completeUploadDatasetName: completeUploadDatasetName,
                optimizingTimeOut: optimizingTimeOut,
              });
            } else {
              this.props.history.push('/login');
            }
          }, 1000 * 60 * 2);
        },
        () => {
          //this.props.history.push('/login');
          //console.log('optimize dataset error:' + error);

          this.setState({
            showSelectModuleTable: false,
            headerTab: 1,
            datasetTableTab: 1,
            showOptimizedCompletedNotification: true,
            timeOutSession: true,
          });

          this.props.history.push('/login');
        }
      );
  };

  //update database to set the value showCompletedNotification to false
  disableCompleteUploadNotification = async () => {
    const newDataset = await this.state.fileArray.map(dataset => {
      dataset.showCompletedNotification = false;

      return dataset;
    });

    await this.setState({
      fileArray: newDataset.filter(file => !file.errors),
      showCompletedNotification: false,
    });

    /*
    Update in Cloudant database
*/

    actions.updateAvailableDataset([this.props.id, this.state.fileArray]).then(
      () => { },
      () => {
        //this.props.history.push('/login');
        this.setState({
          showSelectModuleTable: false,
          headerTab: 1,
          datasetTableTab: 0,
          showOptimizedCompletedNotification: true,
          timeOutSession: true,
        });
      }
    );
  };

  hasExpired = (expiryDate, isIBMer) => {
    const tem = expiryDate.split('-');
    const tem2 = new Date(tem[2], tem[0] - 1, tem[1]);
    const today = new Date();

    if (today > tem2 && !isIBMer) return true;
    else return false;
  };

  UNSAFE_componentWillMount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  async componentDidMount() {
    let periodicFetch = false;
    let showCompletedNotification = false;
    let showErrorNotification = false;
    let showOptimizedCompletedNotification = false;
    let showOptimizingNotificationAvailableDataset = false;
    let someUploading = false;
    let someOpitmizing = false;
    let errorMessage = '';
    let disableOpitmizationDueToExpiry = false;
    let completeUploadDatasetName = '';
    let optimizingTimeOut = false;

    //first initial fetch
    await this.props.checkAuth();

    //fetch data sets from the Cloudant database
    if (this.props.id) {
      await this.props.fetchDataset(this.props.id);

      const errors = await this.props.errors;
      //console.log('optimizer page fetch data errors: ', errors);

      if (errors === undefined || errors.length == 0) {
        const userAgreement = await this.props.acceptUserAgreement;
        //console.log('user agreement on opitmizer page: ', userAgreement);

        if (typeof userAgreement === 'undefined')
          this.props.history.push('/agreement');

        const datasets =
          typeof this.props.datasets === 'undefined' ? [] : this.props.datasets;

        const optimizedDatasets =
          typeof this.props.optimizedDatasets === 'undefined'
            ? []
            : this.props.optimizedDatasets;

        const fileArrayHistory =
          typeof this.props.fileArrayHistory === 'undefined'
            ? []
            : this.props.fileArrayHistory;

        const expiryDate =
          typeof this.props.expiryDateFormat === 'undefined'
            ? ''
            : this.props.expiryDateFormat;

        const isIBMer = this.props.isIBMer;

        disableOpitmizationDueToExpiry = this.hasExpired(expiryDate, isIBMer);

        //const userAgreement = this.props.acceptUserAgreement;

        /*
          check if there is any dataset that has a status of uploading
          or opitmizing
          if so need to fetch dataset periodically
          */

        if (datasets.length > 0 || optimizedDatasets.length > 0) {
          someUploading = datasets.some(
            item => item.fileUploadStatus === 'uploading'
          );
          someOpitmizing = optimizedDatasets.some(
            item => item.optimizing === true
          );
          periodicFetch = someUploading || someOpitmizing;

          //if there is any errors on the uploaded dataset
          //handle errors
          showErrorNotification = datasets.some(item => {
            if (item.errors) errorMessage = item.status;

            return item.errors;
          });

          optimizingTimeOut = optimizedDatasets.some(
            item => item.errors === true
          );

          let obj = datasets.find(o => o.showCompletedNotification);

          if (obj) {
            completeUploadDatasetName = obj.name;
            showCompletedNotification = true;
          }

          showOptimizedCompletedNotification = optimizedDatasets.some(
            item => item.showOptimizedCompletedNotification === true
          );

          if (this.props.optimizingDatasetName)
            showOptimizingNotificationAvailableDataset = true;
        }

        await this.setState({
          fileArray: datasets.filter(file => !file.errors),
          fileArrayOptimized: optimizedDatasets,
          fileArrayHistory: fileArrayHistory,
          token: this.props.token,
          id: this.props.id,
          name: this.props.name,
          showCompletedNotification: showCompletedNotification,
          showOptimizedCompletedNotification: showOptimizedCompletedNotification,
          showOptimizingNotificationAvailableDataset: showOptimizingNotificationAvailableDataset,
          showErrorNotification: showErrorNotification,
          errorMessage: errorMessage,
          lastOpitmizedDatasetName: this.props.lastOpitmizedDatasetName,
          lastOpitmizedSuccessNumber: this.props.lastOpitmizedSuccessNumber,
          optimizingDatasetName: this.props.optimizingDatasetName,
          disableOpitmizationDueToExpiry: disableOpitmizationDueToExpiry,
          completeUploadDatasetName: completeUploadDatasetName,
          optimizingTimeOut: optimizingTimeOut,
          timeOutSession: false,
          isIBMer: isIBMer,
        });
      } else {
        //if (errors[0].status === 401) this.props.history.push('/login');
        this.props.history.push('/login');
      }
    } else {
      this.props.history.push('/login');
    }

    //periodic fetching if needed
    if (periodicFetch) {
      this.timer = setInterval(async () => {
        await this.props.dispatch(actions.checkAuth());

        //fetch data sets from the Cloudant database
        if (this.props.id) {
          await this.props.dispatch(actions.fetchDatasets(this.props.id));

          const datasets =
            typeof this.props.datasets === 'undefined'
              ? []
              : this.props.datasets;

          const optimizedDatasets =
            typeof this.props.optimizedDatasets === 'undefined'
              ? []
              : this.props.optimizedDatasets;

          const fileArrayHistory =
            typeof this.props.fileArrayHistory === 'undefined'
              ? []
              : this.props.fileArrayHistory;

          const expiryDate =
            typeof this.props.expiryDateFormat === 'undefined'
              ? ''
              : this.props.expiryDateFormat;

          const isIBMer = this.props.isIBMer;

          disableOpitmizationDueToExpiry = this.hasExpired(expiryDate, isIBMer);

          /*
          check if there is any dataset that has a status of uploading
          if so need to fetch dataset periodically
          */

          if (datasets.length > 0 || optimizedDatasets.length > 0) {
            someUploading = datasets.some(
              item => item.fileUploadStatus === 'uploading'
            );
            someOpitmizing = optimizedDatasets.some(
              item => item.optimizing === true
            );
            periodicFetch = someUploading || someOpitmizing;

            //if there is any errors on the uploaded dataset
            //handle errors
            showErrorNotification = datasets.some(item => {
              if (item.errors) errorMessage = item.status;

              return item.errors;
            });

            optimizingTimeOut = optimizedDatasets.some(
              item => item.errors === true
            );

            let obj = datasets.find(o => o.showCompletedNotification);

            if (obj) {
              completeUploadDatasetName = obj.name;
              showCompletedNotification = true;
            } else {
              showCompletedNotification = false;
            }

            showOptimizedCompletedNotification = optimizedDatasets.some(
              item => item.showOptimizedCompletedNotification === true
            );

            //showOptimizingNotificationAvailableDataset = someOpitmizing;
            if (this.props.optimizingDatasetName)
              showOptimizingNotificationAvailableDataset = true;
            else showOptimizingNotificationAvailableDataset = false;
          }

          if (!periodicFetch) {
            clearInterval(this.timer);
            this.timer = null;
          }

          await this.setState({
            fileUploadStatus: someUploading
              ? this.state.fileUploadStatus
              : 'edit',
            fileUploadInformation: someUploading
              ? this.state.fileUploadInformation
              : [],
            fileArray: datasets.filter(file => !file.errors),
            fileArrayOptimized: optimizedDatasets,
            fileArrayHistory: fileArrayHistory,
            token: this.props.token,
            id: this.props.id,
            name: this.props.name,
            showCompletedNotification: showCompletedNotification,
            showOptimizedCompletedNotification: showOptimizedCompletedNotification,
            showOptimizingNotificationAvailableDataset: showOptimizingNotificationAvailableDataset,
            showErrorNotification: showErrorNotification,
            errorMessage: errorMessage,
            lastOpitmizedDatasetName: this.props.lastOpitmizedDatasetName,
            optimizingDatasetName: this.props.optimizingDatasetName,
            disableOpitmizationDueToExpiry: disableOpitmizationDueToExpiry,
            completeUploadDatasetName: completeUploadDatasetName,
            optimizingTimeOut: optimizingTimeOut,
          });
        } else {
          this.props.history.push('/login');
        }
      }, 1000 * 60 * 3);
    }
  }

  closeUploadConfirmationModal = () => {
    this.setState({
      uploadDuplicatedName: false,
      fileUploadStatus: 'edit',
      fileUploadInformation: [],
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      currentSelectDatasetName: '',
    });
  };

  closeDeleteModalOpitmized = () => {
    this.setState({
      showDeleteModalOptimizedDataset: false,
      currentSelectOptimizedDatasetName: {},
    });
  };

  showDeleteModalTrigger = filename => {
    this.setState({
      showDeleteModal: true,
      currentSelectDatasetName: filename,
    });
  };

  showDeleteModalTriggerOptimizedDataset = file => {
    this.setState({
      showDeleteModalOptimizedDataset: true,
      currentSelectOptimizedDatasetName: file,
    });
  };

  //http://localhost:8080/api/idPayload
  getTokenInfo = () => {
    // Make a request for a user with a given ID
    axios
      .get('http://localhost:8080/api/idPayload')
      .then(function () {
        // handle success
        //console.log(response);
      })
      .catch(function () {
        // handle error
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  render() {
    let bucketName;
    const isAuthenitcated = this.props.id;
    const agreement = this.props.acceptUserAgreement;
    const maintenance = this.props.maintenance;

    if (this.props.id) {
      bucketName = this.props.id;
    } else {
      bucketName = '';
    }

    //debugger;
    if (isAuthenitcated && agreement && !maintenance) {
      return (
        <>
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />

          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            remainingTime={this.state.timeoutCountdown}
          />

          {!this.state.showSelectModuleTable && (
            <OptimizeDataset
              handleChangeSelectFileForUpload={
                this.handleChangeSelectFileForUpload
              }
              handleDelectSelectFileForUpload={
                this.handleDelectSelectFileForUpload
              }
              validateFileUpload={this.validateFileUpload}
              handleUploadFileSubmitButton={this.handleUploadFileSubmitButton}
              deleteSelectedRowAvailableDataset={
                this.deleteSelectedRowAvailableDataset
              }
              deleteSelectedRowOptimizedDataset={
                this.deleteSelectedRowOptimizedDataset
              }
              getSelectedRowAvailableDataset={
                this.getSelectedRowAvailableDataset
              }
              getSelectedRowOptimizedDataset={
                this.getSelectedRowOptimizedDataset
              }
              showDeletedNotificationCloseButtonAvailableDataset={
                this.showDeletedNotificationCloseButtonAvailableDataset
              }
              showDeletedNotificationCloseButtonOptimizedDataset={
                this.showDeletedNotificationCloseButtonOptimizedDataset
              }
              fileUploadInformation={this.state.fileUploadInformation}
              fileUploadStatus={this.state.fileUploadStatus}
              selectedSingleFileOptimizedDataset={
                this.state.selectedSingleFileOptimizedDataset
              }
              selectedSingleFileAvailableDataset={
                this.state.selectedSingleFileAvailableDataset
              }
              optimizingDatasetName={this.state.optimizingDatasetName}
              showCompletedNotification={this.state.showCompletedNotification}
              showErrorNotification={this.state.showErrorNotification}
              errorMessage={this.state.errorMessage}
              fileArray={this.state.fileArray}
              fileArrayOptimized={this.state.fileArrayOptimized}
              showDeletedNotificationAvailableDataset={
                this.state.showDeletedNotificationAvailableDataset
              }
              showDeletedNotificationOptimizedDataset={
                this.state.showDeletedNotificationOptimizedDataset
              }
              showOptimizingNotificationAvailableDataset={
                this.state.showOptimizingNotificationAvailableDataset
              }
              showOptimizedCompletedNotification={
                this.state.showOptimizedCompletedNotification
              }
              showSelectModuleTableButton={this.showSelectModuleTableButton}
              selectedModuleTableData={this.state.selectedModuleTableData}
              datasetTableTab={this.state.datasetTableTab}
              showOptimizingNotificationCloseButton={
                this.showOptimizingNotificationCloseButton
              }
              showOpitimizedCompleteNotificationCloseButton={
                this.showOpitimizedCompleteNotificationCloseButton
              }
              showOpitimizedCompleteNotificationTimeOutCloseButton={
                this.showOpitimizedCompleteNotificationTimeOutCloseButton
              }
              disableCompleteUploadNotification={
                this.disableCompleteUploadNotification
              }
              bucketName={bucketName}
              lastOpitmizedDatasetName={this.state.lastOpitmizedDatasetName}
              lastOpitmizedSuccessNumber={this.state.lastOpitmizedSuccessNumber}
              disableOpitmizationDueToExpiry={
                this.state.disableOpitmizationDueToExpiry
              }
              closeUploadConfirmationModal={this.closeUploadConfirmationModal}
              uploadDuplicatedName={this.state.uploadDuplicatedName}
              uploadDuplicatedNameList={this.state.uploadDuplicatedNameList}
              completeUploadDatasetName={this.state.completeUploadDatasetName}
              showDeleteModal={this.state.showDeleteModal}
              showDeleteModalTrigger={this.showDeleteModalTrigger}
              showDeleteModalTriggerOptimizedDataset={
                this.showDeleteModalTriggerOptimizedDataset
              }
              showDeleteModalOptimizedDataset={
                this.state.showDeleteModalOptimizedDataset
              }
              closeDeleteModal={this.closeDeleteModal}
              closeDeleteModalOpitmized={this.closeDeleteModalOpitmized}
              currentSelectDatasetName={this.state.currentSelectDatasetName}
              currentSelectOptimizedDatasetName={
                this.state.currentSelectOptimizedDatasetName
              }
              optimizingTimeOut={this.state.optimizingTimeOut}
              timeOutSession={this.state.timeOutSession}
              ref={this.child}
            />
          )}
          {this.state.showSelectModuleTable && (
            <SelectModuleTable
              currentDataset={this.state.currentDataset}
              clickOptimizeButton={this.clickOptimizeButton}
              showSelectModuleTableButtonDisable={
                this.showSelectModuleTableButtonDisable
              }
              modulesSelectedOnSelectModuleTable={
                this.modulesSelectedOnSelectModuleTable
              }
              showOptimizingNotificationAvailableDataset={
                this.state.showOptimizingNotificationAvailableDataset
              }
              disableOpitmizationDueToExpiry={
                this.state.disableOpitmizationDueToExpiry
              }
            />
          )}
        </>
      );
    } else if (maintenance) {
      return (
        <div>
          <h4 className="maintenance_text">
            ABO Trial Cloud Service is undergoing maintenance. Please try again
            later.
          </h4>

          <img
            className="maintenance_image"
            src={process.env.PUBLIC_URL + '/img/maintenance_icon.svg'}
            alt="maintenance icon"
          />
        </div>
      );
    }

    return (
      <Loading
        active
        className="landing_page_loading_icon"
        description="Active loading indicator"
        small={false}
        withOverlay={false}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    checkAuth: () => dispatch(actions.checkAuth()),
    dispatch,
  };
};

const mapStateToProps = state => {
  //debugger;
  return {
    datasets: state.datasets.data[0],
    optimizedDatasets: state.datasets.data[1],
    token: state.auth.token,
    auth: state.auth.isAuth,
    id: state.auth.id,
    name: state.auth.name,
    acceptUserAgreement: state.datasets.data[2],
    fileArrayHistory: state.datasets.data[3],
    lastOpitmizedDatasetName: state.datasets.data[4],
    lastOpitmizedSuccessNumber: state.datasets.data[5],
    startTrialDate: state.datasets.data[6],
    expiryDateFormat: state.datasets.data[7],
    optimizingDatasetName: state.datasets.data[8],
    notifications: state.datasets.data[9],
    maintenance: state.datasets.data[10],
    isIBMer: state.datasets.data[11],
    errors: state.datasets.errors,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);
