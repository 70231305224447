/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React, { Component } from 'react';
import './App.scss';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import TutorialHeader from './components/TutorialHeader';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import LoginPage from './content/LoginPage';
import Optimizer from './content/Optimizer';
import Instruction from './content/Instruction';
import AgreementPage from './content/AgreementPage';
import ResourcesPage from './content/ResourcesPage';
import UsageSummary from './content/UsageSummary';

const store = require('./reducers').init();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreement: false,
      updateAgreement: this.updateAgreement,
    };
  }

  updateAgreement = () => {
    this.setState({ agreement: true });
  };

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <TutorialHeader />
          <Content>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/instructions"
                render={props => (
                  <Instruction
                    {...props}
                    agreement={this.state.agreement}
                    updateAgreement={this.updateAgreement}
                  />
                )}
              />
              <Route
                exact
                path="/optimizer"
                render={props => (
                  <Optimizer {...props} agreement={this.state.agreement} />
                )}
              />
              <Route exact path="/agreement" component={AgreementPage} />
              <Route exact path="/history" component={UsageSummary} />
              <Route exact path="/resources" component={ResourcesPage} />
            </Switch>
          </Content>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
