/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'carbon-components-react';

import * as actions from 'actions';

const margin_top_20 = {
  marginTop: '20px',
};

class AgreementPage extends React.Component {
  async componentDidMount() {
    await this.props.checkAuth();

    if (this.props.id) {
      await this.props.fetchDataset(this.props.id);
    } else {
      this.props.history.push('/login');
    }
  }

  //handleUserAgreementDecline = () => {};

  handleUserAgreementSubmit = () => {
    /*
    Update in Cloudant database
    */

    if (this.props.id) {
      actions.createProfile([this.props.id, this.props.email]).then(
        () => {
          this.sleep(1000).then(() => {
            //go to the landing page
            this.props.history.push('/instructions');
          });
        },
        () => {
          this.props.history.push('/login');
        }
      );
    }
  };

  sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  render() {
    const acceptUserAgreement = this.props.acceptUserAgreement;

    return (
      <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
        <div
          className="bx--row"
          style={{ marginBottom: '5rem', marginTop: '5rem' }}>
          <div className="bx--col-lg-12">
            <h4 className="productive-heading-04">
              IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
              Agreement
            </h4>
            <p className="paragraph_styles_justify" style={margin_top_20}>
              The IBM Automatic Binary Optimizer for z/OS (ABO) Trial Cloud
              Service is under the terms and conditions of the
              <br />
              <a href="https://www-03.ibm.com/software/sla/sladb.nsf/pdf/8815-01/$file/i126-8815-01_05-2020_en_US.pdf">
                Service Description for IBM Automatic Binary Optimizer for z/OS
                Trial Cloud Service
              </a>
              <br />
            </p>
            <p className="paragraph_styles_justify" style={margin_top_20}>
              Translated versions of the Service Description for IBM Automatic
              Binary Optimizer for z/OS Trial Cloud Service
              <br />
              <a href="https://www-03.ibm.com/software/sla/sladb.nsf/sla/sd-8815-01">
                https://www-03.ibm.com/software/sla/sladb.nsf/sla/sd-8815-01
              </a>
            </p>

            <p className="paragraph_styles_justify" style={margin_top_20}>
              Client is authorized to use the IBM Automatic Binary Optimizer for
              z/OS Trial Cloud Service during the specified trial period for the
              purpose of evaluating its functionality and technology.{' '}
              <span style={{ fontWeight: 'bold' }}>
                The optimized binaries produced by the IBM Automatic Binary
                Optimizer for z/OS Trial Cloud Service are not allowed to be
                used in a production environment or for commercial purposes.
              </span>
            </p>
            <p className="paragraph_styles_justify" style={margin_top_20}>
              The evaluation period for the IBM Automatic Binary Optimizer for
              z/OS Trial Cloud Service is 90 days starting from the first
              successful processing of an uploaded data set.
            </p>
            <p className="paragraph_styles_justify" style={margin_top_20}>
              Clients will be notified via email of the end of the 90 day
              evaluation period, at which point all data sets will be
              automatically deleted from the ABO Trial Cloud Service, and use of
              the service will be disabled.
            </p>
            <div style={{height: '100px'}}>
                <div style={{height: '40px'}}>
               </div>
            {!acceptUserAgreement && (      
                <div style={{height: '60px'}}>
                <Button
                    style={{ float: 'right' }}
                    onClick={this.handleUserAgreementSubmit}>
                  Accept
                </Button>

                <a href="/appid/logout">
                  {' '}
                  <Button
                    style={{ float: 'right', right: '20px' }}
                    kind="secondary"
                    onClick={() => {
                      window.location.href = '/appid/logout';
                    }}>
                    Decline
                  </Button>
                </a>
              </div>
            )}
          </div>
          </div>
          <div className="bx--col-lg-4"></div>
        </div>

        <div className="bx--row agreement-page-bottom">
          <div 
            className="bx--col-lg-12"
            style={{ borderTop: 'solid 1px #dcdcdc' }}>
            {' '}
            <p style={{fontSize: 'smaller' }}>
              IBM, the IBM logo, IBM Z, z14, z15, z16, and z/OS are trademarks or
              registered trademarks of International Business Machines
              Corporation, in the United States and/or other countries. Other
              product and service names might be trademarks of IBM or other
              companies. A current list of IBM trademarks is available on
              ibm.com/trademark.
            </p>
          </div>

          <div className="bx--col-lg-4"></div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    checkAuth: () => dispatch(actions.checkAuth()),
    dispatch,
  };
};

const mapStateToProps = state => {
  //debugger;
  return {
    datasets: state.datasets.data[0],
    optimizedDatasets: state.datasets.data[1],
    token: state.auth.token,
    id: state.auth.id,
    name: state.auth.name,
    email: state.auth.email,
    acceptUserAgreement: state.datasets.data[2],
    fileArrayHistory: state.datasets.data[3],
    lastOpitmizedDatasetName: state.datasets.data[4],
    lastOpitmizedSuccessNumber: state.datasets.data[5],
    startTrialDate: state.datasets.data[6],
    expiryDateFormat: state.datasets.data[7],
    optimizingDatasetName: state.datasets.data[8],
    notifications: state.datasets.data[9],
    maintenance: state.datasets.data[10],
  };
};

AgreementPage.propTypes = {
  history: PropTypes.any,
  acceptUserAgreement: PropTypes.bool,
  numberProp: PropTypes.number,
  id: PropTypes.string,
  email: PropTypes.string,
  checkAuth: PropTypes.func,
  fetchDataset: PropTypes.func,
};

//export default AgreementPage;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementPage)
);
