/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import {
  FETCH_DATASETS_BY_ID_INIT,
  FETCH_DATASETS_SUCCESS,
  FETCH_DATASETS_BY_ID_SUCCESS,
  FETCH_DATASETS_FAIL,
  FETCH_DATASETS_INIT,
} from '../actions/types';

//initial state
const INTIAL_STATE = {
  datasets: {
    loading: false,
    data: [],
    errors: [],
  },

  dataset: {
    data: {},
  },
};

export const datasetsReducer = (state = INTIAL_STATE.datasets, action) => {
  switch (action.type) {
    case FETCH_DATASETS_INIT:
      return { ...state, loading: true, data: [], errors: [] };
    case FETCH_DATASETS_FAIL:
      return { ...state, loading: false, data: [], errors: [action.errors] };
    case FETCH_DATASETS_SUCCESS:
      return { ...state, loading: false, data: action.datasets, errors: [] };
    default:
      return state;
  }
};

export const selectedDatasetReducer = (
  state = INTIAL_STATE.dataset,
  action
) => {
  switch (action.type) {
    case FETCH_DATASETS_BY_ID_INIT:
      return { ...state, data: {} };
    case FETCH_DATASETS_BY_ID_SUCCESS:
      //debugger;
      return { ...state, data: action.dataset };
    default:
      return state;
  }
};
