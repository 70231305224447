import axios from 'axios';
//import authService from './auth-service';

class AxiosService {
  tokenKey = 'auth_token';

  axiosInstance = {};

  constructor() {
    this.initInstance();
  }

  //get token from local storage
  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  //create axios instance with configuration
  initInstance() {
    this.axiosInstance = axios.create({
      baseURL: '/api/v1',
      timeout: 20000,
    });

    this.axiosInstance.interceptors.request.use(config => {
      const token = this.getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    return this.axiosInstance;
  }

  getInstance() {
    return this.axiosInstance || this.initInstance();
  }
}

export default new AxiosService();
