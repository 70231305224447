/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, DataTable, Loading } from 'carbon-components-react';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../Optimizer/IdleTimeOutModal';
import * as actions from 'actions';
import { Add16 } from '@carbon/icons-react';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,

  TableToolbarContent,
} = DataTable;

const paragraph_style = {
  marginTop: '20px',
};

let countdownInterval;
let timeout;

class UsageSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { timeout: 1000 * 60 * 25 };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  //functions for handling session timeout
  //----------------------------------------------------

  clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    if (!this.state.showModal) {
      this.clearSessionInterval();
      this.clearSessionTimeout();
    }
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const delay = 1000 * 1 * 1;
    if (!this.state.showModal) {
      timeout = setTimeout(() => {
        let countDown = 60;
        this.setState({ showModal: true, timeoutCountdown: countDown });
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            this.setState({ timeoutCountdown: --countDown });
          } else {
            //this.props.history.push('/login');

            this.props.history.push({
              pathname: '/login',
              state: {
                showModal: true,
              },
            });
          }
        }, 1000);
      }, delay);
    }
  }

  handleClose() {
    this.clearSessionInterval();
    this.clearSessionTimeout();
    this.setState({ showModal: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.clearSessionInterval();
    this.clearSessionTimeout();
    //this.props.history.push('/login');
    window.location.href = '/appid/logout';
  }
  //----------------------------------------------------

  async componentDidMount() {
    //first initial fetch
    await this.props.checkAuth();

    //fetch data sets from the Cloudant database
    if (!this.props.auth) {
      this.props.history.push('/login');
    }
  }

  render() {
    let rows = [];

    this.props.fileArrayHistory &&
      this.props.fileArrayHistory.map((file, index) => {
        rows.push({
          id: index,
          datasetname: file.name,
          hardwareLevel: file.hardware,
          zOSversion: file.software,
          numberModules: file.totalNumberModule,
          optimizedModule: file.optimizedModule,
          optimizedDate: file.optimizedDate,
          numberOfUpload: file.totalNumberModule,
          ABOErrors: file.ABOErrors,
        });
      });

    // We would have a headers array like the following
    const headers = [
      {
        // `key` is the name of the field on the row object itself for the header
        key: 'datasetname',
        // `header` will be the name you want rendered in the Table Header
        header: 'Data set name',
      },
      {
        key: 'optimizedDate',
        header: 'Date optimized',
      },
      {
        key: 'numberOfUpload',
        header: 'Total # of uploads',
      },
      {
        key: 'optimizedModule',
        header: 'Optimized modules',
      },
      {
        key: 'ABOErrors',
        header: 'Modules not optimized',
      },
      {
        key: 'hardwareLevel',
        header: 'Hardware level',
      },
      {
        key: 'zOSversion',
        header: 'z/OS version',
      },
    ];

    const endDate =
      typeof this.props.expiryDateFormat === 'undefined'
        ? ''
        : this.props.expiryDateFormat.replace(/-/g, '/');

    let date2;
    let today;
    let diffTime;
    let diffDays;

    if (endDate) {
      date2 = new Date(endDate);
      today = new Date();
      diffTime = date2 - today;
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 0) diffDays = 0;
    }

    const maintenance = this.props.maintenance;

    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
          remainingTime={this.state.timeoutCountdown}
        />
        <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div className="bx--row landing-page__tab-content">
            <div className="bx--col-lg-16">
              <div>
                <h3
                  style={{ marginTop: '20px' }}
                  className="productive-heading-03">
                  Your usage summary
                </h3>

                {!this.props.isIBMer && (
                  <div>
                    {' '}
                    <p style={paragraph_style} className="paragraph_styles">
                      Trial start date: {this.props.startTrialDate}
                    </p>
                    <p style={paragraph_style} className="paragraph_styles">
                      Trial end date: {this.props.expiryDateFormat}
                    </p>
                    <p style={paragraph_style} className="paragraph_styles">
                      Days remaining: {diffDays}
                    </p>{' '}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="bx--row">
            <div className="bx--col-lg-16">
              <DataTable
                rows={rows}
                headers={headers}
                render={({ rows, headers, getHeaderProps }) => (
                  <TableContainer>
                    <TableToolbar>
                      {/* pass in `onInputChange` change here to make filtering work */}

                      <TableToolbarContent>
                        <Button kind="primary" renderIcon={Add16}>
                          Print
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table>
                      <TableHead>
                        <TableRow>
                          {headers.map(header => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {rows.map(row => (
                          <TableRow key={row.id}>
                            {row.cells.map(cell => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: () => dispatch(actions.checkAuth()),
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    dispatch,
  };
};

const mapStateToProps = state => {
  //console.log('Useage summary page states:', state);

  //debugger;
  return {
    datasets: state.datasets.data[0],
    optimizedDatasets: state.datasets.data[1],
    token: state.auth.token,
    auth: state.auth.isAuth,
    id: state.auth.id,
    name: state.auth.name,
    acceptUserAgreement: state.datasets.data[2],
    fileArrayHistory: state.datasets.data[3],
    lastOpitmizedDatasetName: state.datasets.data[4],
    lastOpitmizedSuccessNumber: state.datasets.data[5],
    startTrialDate: state.datasets.data[6],
    expiryDateFormat: state.datasets.data[7],
    optimizingDatasetName: state.datasets.data[8],
    notifications: state.datasets.data[9],
    maintenance: state.datasets.data[10],
    isIBMer: state.datasets.data[11],
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsageSummary)
);
