/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../Optimizer/IdleTimeOutModal';

import * as actions from 'actions';

const margin_top_20 = {
  marginTop: '20px',
};

const margin_top_15 = {
  marginTop: '15px',
};

let countdownInterval;
let timeout;

class ResourcesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      timeout: 1000 * 60 * 25,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  //functions for handling session timeout
  //----------------------------------------------------

  clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    if (!this.state.showModal) {
      this.clearSessionInterval();
      this.clearSessionTimeout();
    }
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const delay = 1000 * 1 * 1;
    if (!this.state.showModal) {
      timeout = setTimeout(() => {
        let countDown = 60;
        this.setState({ showModal: true, timeoutCountdown: countDown });
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            this.setState({ timeoutCountdown: --countDown });
          } else {
            //this.props.history.push('/login');

            this.props.history.push({
              pathname: '/login',
              state: {
                showModal: true,
              },
            });
          }
        }, 1000);
      }, delay);
    }
  }

  handleClose() {
    this.clearSessionInterval();
    this.clearSessionTimeout();
    this.setState({ showModal: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.clearSessionInterval();
    this.clearSessionTimeout();
    //this.props.history.push('/login');
    window.location.href = '/appid/logout';
  }
  //----------------------------------------------------

  async componentDidMount() {
    //first initial fetch
    await this.props.checkAuth();

    //fetch data sets from the Cloudant database
    if (!this.props.auth) {
      //this.props.history.push('/login');
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
          remainingTime={this.state.timeoutCountdown}
        />
        <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div className="bx--row landing-page__tab-content">
            <div className="bx--col-lg-12">
              <h3 className="productive-heading-02">Resources</h3>
              <h2 style={margin_top_20} className="productive-heading-02">
                Introduction to the ABO for z/OS Trial Cloud Service
              </h2>

              <div style={margin_top_15}>
                <a href="/api/download2/Tutorial_PDF.pdf" target="_blank">
                  IBM Automatic Binary Optimizer for z/OS (ABO) Trial Cloud
                  Service Hands-on Tutorial
                </a>
              </div>

              <h2 style={margin_top_20} className="productive-heading-02">
                Sample COBOL program and ABO optimized versions to be used with
                the Hands-on Tutorial
              </h2>
              <div style={margin_top_15}>
                <a href="/api/download2/COMPTX.XMIT.TRS">
                  Sample stand-alone COBOL program 'COMPTX' in XMIT.TRS format
                </a>
              </div>

              <div style={margin_top_15}>
                <a href="/api/download2/MANY.PROGS.XMIT.TRS">
                  Sample data set with multiple COBOL modules in XMIT.TRS format
                </a>
              </div>

              <div style={margin_top_15}>
                <a href="/api/download2/BOZ.COMPTX.XMIT.TRS.z14.zip">
                  IBM ABO Optimized COBOL program 'COMPTX' package in ZIP format
                  for z14
                </a>
              </div>

              <div style={margin_top_15}>
                <a href="/api/download2/BOZ.COMPTX.XMIT.TRS.z15.zip">
                  IBM ABO Optimized COBOL program 'COMPTX' package in ZIP format
                  for z15
                </a>
              </div>

              <div style={margin_top_15}>
                <a href="/api/download2/BOZ.COMPTX.XMIT.TRS.z16.zip">
                  IBM ABO Optimized COBOL program 'COMPTX' package in ZIP format
                  for z16
                </a>
              </div>

              <h2 style={margin_top_20} className="productive-heading-02">
                Determine the best COBOL modules to optimize with ABO
              </h2>

              <div style={margin_top_15}>
                Identifying Top CPU Consuming COBOL Modules (
                <a
                  href="https://mediacenter.ibm.com/media/t/1_ygabnyso"
                  rel="noopener noreferrer"
                  target="_blank">
                  Video
                </a>{' '}
                |{' '}
                <a
                  href="https://www-01.ibm.com/support/docview.wss?uid=ibm10738777"
                  rel="noopener noreferrer"
                  target="_blank">
                  White paper
                </a>
                )
              </div>

              <h2 style={margin_top_20} className="productive-heading-02">
                Information about IBM Automatic Binary Optimizer for z/OS
              </h2>

              <div style={margin_top_15}>
                <a
                  href="https://www.ibm.com/docs/en/abo"
                  rel="noopener noreferrer"
                  target="_blank">
                  ABO documentation
                </a>{' '}
                - A resource containing technical documentation for ABO
              </div>

              <div style={margin_top_15}>
                <a
                  href="https://www-01.ibm.com/support/docview.wss?uid=swg27046990"
                  rel="noopener noreferrer"
                  target="_blank">
                  ABO documentation library
                </a>{' '}
                - A collection of announcement letters, data sheets, and product
                documentation for ABO
              </div>

              <div style={margin_top_15}>
                <a
                  href="https://www.ibm.com/products/automatic-binary-optimizer-zos"
                  rel="noopener noreferrer"
                  target="_blank">
                  ABO product page
                </a>{' '}
                - ABO product page that introduces ABO benefits, details,
                pricing, and case studies
              </div>

              <h2 style={margin_top_20} className="productive-heading-02">
                Information about IBM products that work with ABO
              </h2>

              <div style={margin_top_15}>
                <a
                  href="https://mediacenter.ibm.com/media/t/1_q99kohqg"
                  target="_blank"
                  rel="noopener noreferrer">
                  Debugging ABO-Optimized COBOL Programs with IBM Debug for z
                  Systems (Video)
                </a>
              </div>

              <div style={margin_top_15}>
                <a
                  href="https://www.ibm.com/products/cobol-compiler-zos"
                  rel="noopener noreferrer"
                  target="_blank">
                  IBM Enterprise COBOL for z/OS product page
                </a>{' '}
                - Enterprise COBOL for z/OS product page that gives an overview
                of COBOL key features
              </div>

              <div style={margin_top_15}>
                <a
                  href="https://www.ibm.com/products/app-delivery-foundation-for-zos"
                  rel="noopener noreferrer"
                  target="_blank">
                  IBM Application Delivery Foundation for z/OS product page
                </a>{' '}
                - Product page for ADFz, which is a a suite of integrated,
                full-featured tools to analyze ABO optimized modules
              </div>

              <h2 style={margin_top_20} className="productive-heading-02">
                Service Agreement
              </h2>

              <div style={margin_top_15}>
                <a href="/agreement" target="_blank" rel="noopener noreferrer">
                  IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
                  Agreement
                </a>
              </div>
            </div>
            <div className="bx--col-lg-4"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: () => dispatch(actions.checkAuth()),
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    dispatch,
  };
};

//maping redux store to the component using props
//passing the auth to the component from redux store
function mapStateToProps(state) {
  //debugger;
  return {
    id: state.auth.id,
    auth: state.auth.isAuth,
    name: state.auth.name,
    email: state.auth.email,
  };
}

//connect function to map the state to props in the component
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcesPage)
);
