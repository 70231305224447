import React from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderMenu,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';

/*
import Notification20 from '@carbon/icons-react/lib/notification/20';
import UserAvatar20 from '@carbon/icons-react/lib/user--avatar/20';
import AppSwitcher20 from '@carbon/icons-react/lib/app-switcher/20';
*/

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class TutorialHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
      email: 'compclds@ca.ibm.com',
    };
  }

  render() {
    //console.log('this props auth is:', this.props.auth);
    let homepage;

    if (this.props.auth) {
      homepage = '/';
    } else {
      homepage = '/';
    }

    return (
      <Header aria-label="ABO Trial Cloud Service">
        <SkipToContent />
        <HeaderName element={Link} to={homepage} prefix="ABO">
          Trial Cloud Service
        </HeaderName>

        <HeaderNavigation aria-label="Carbon Tutorial">
          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/instructions">
              Instructions
            </HeaderMenuItem>
          )}

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/optimizer">
              Optimizer
            </HeaderMenuItem>
          )}

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/history">
              History
            </HeaderMenuItem>
          )}

          {this.props.auth && (
            <HeaderMenuItem element={Link} to="/resources">
              Resources
            </HeaderMenuItem>
          )}

          <HeaderMenu
            className="user_support_menu"
            menuLinkName="Support"
            aria-label="Support Menu">
            <HeaderMenuItem>
              <a className="bx--link" href={`mailto:${this.state.email}`}>
                compclds@ca.ibm.com
              </a>
            </HeaderMenuItem>
          </HeaderMenu>
        </HeaderNavigation>

        <HeaderGlobalBar>
          {this.props.auth && (
            <HeaderMenu
              className="user_profile_menu"
              menuLinkName={this.props.name}
              aria-label="User profile">
              <HeaderMenuItem href="/appid/logout">Logout</HeaderMenuItem>
            </HeaderMenu>
          )}
        </HeaderGlobalBar>
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth.isAuth,
    name: state.auth.name,
    email: state.auth.email,
    id: state.auth.id,
  };
}

export default withRouter(connect(mapStateToProps)(TutorialHeader));
