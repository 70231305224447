/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';

import { DataTable, Button, Loading } from 'carbon-components-react';

import DownloadIcon16 from '@carbon/icons-react/lib/download/16';
import { Delete16 } from '@carbon/icons-react';

import Modal from './Modal';
//import Stopwatch from './StopWatch';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableExpandHeader,
  TableExpandedRow,
  TableExpandRow,
} = DataTable;

// We would have a headers array like the following
const headers = [
  {
    // `key` is the name of the field on the row object itself for the header
    key: 'datasetname',
    // `header` will be the name you want rendered in the Table Header
    header: 'Data sets name',
  },
  {
    key: 'numberModules',
    header: 'Number of modules',
  },
  {
    key: 'optimizationOptions',
    header: 'Optimization options',
  },

  {
    key: 'dateOptimized',
    header: 'Date optimized',
  },

  {
    key: 'downloadOrTimer',
    header: 'Downloads',
  },
  {
    key: 'logs',
    header: '',
  },
  {
    key: 'deleteOrSpinner',
    header: '',
  },
  {
    key: 'dropdown',
    header: '',
  },
];

class OptimizedDatasetTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showBatchDeleteModal: false };
  }

  //Click Event Handler when the user select or un-selected data set
  poolSelectedHandler = row => {
    //console.log('selected row:', row);

    this.props.getSelectedRowOptimizedDataset(row);
  };

  //when user click batch delete
  showBatchDeleteModal = () => {
    this.setState({ showBatchDeleteModal: true });
  };

  render() {
    let rows = [];

    if (this.props.completedData && this.props.completedData.length > 0) {
      this.props.completedData.map((file, index) => {
        //console.log('file:' + file + ' index ' + index);
        if (file.optimized) {
          rows.push({
            id: index,
            datasetname: file.name,
            numberModules: (
              <div>
                {file.optimizedSucceedNumber}/{file.numberSelected} Optimized{' '}
              </div>
            ),
            optimizationOptions: file.hardware + ' | ' + file.software,

            dateOptimized: file.optimizedDate,

            downloadOrTimer:
              file.optimizedSucceedNumber > 0 ? (
                <a
                  href={`/api/download/${file.downloadFileName}?bucket=${this.props.bucketName}`}>
                  <Button kind="ghost" renderIcon={DownloadIcon16}>
                    Data set
                  </Button>
                </a>
              ) : (
                'Not available'
              ),
            logs: file.errors ? (
              <div style={{ paddingLeft: '14px' }}>Time out</div>
            ) : (
              <a
                href={`/api/download/${file.summaryFileName}?bucket=${this.props.bucketName}`}
                target="_blank"
                rel="noopener noreferrer">
                <Button kind="ghost" renderIcon={DownloadIcon16}>
                  Optimization log
                </Button>
              </a>
            ),
            deleteOrSpinner: (
              <Button
                className="some-class"
                disabled={false}
                hasIconOnly
                iconDescription="delete data set"
                kind="ghost"
                onClick={() =>
                  this.props.showDeleteModalTriggerOptimizedDataset(file)
                }
                renderIcon={Delete16}
                size="default"
                tabIndex={0}
                tooltipAlignment="center"
                tooltipPosition="bottom"
                type="button"
              />
            ),
            dropdown: (
              <div className="bx--row">
                <div className="bx--col-lg-3">
                  <p className="code-01" style={{ paddingLeft: '30px' }}>
                    <ul>
                      <li>
                        Modules optimized: {file.optimizedSucceedNumber} of{' '}
                        {file.numberSelected}
                      </li>
                      <li>
                        Modules selected : {file.numberSelected} of{' '}
                        {file.totalNumberModule}
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="bx--col-lg-3">
                  <p className="code-01">
                    Download and review the optimization log to find the reasons
                    for the modules not optimized
                  </p>

                  <div className="bx--tooltip__footer">
                    <a
                      className="code-01"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.ibm.com/docs/en/abo/2.2?topic=guide-cobol-module-requirements">
                      COBOL module requirements
                    </a>
                  </div>

                  <div className="bx--col-lg-8"></div>
                </div>
              </div>
            ),
          });
        } else if (file.optimizing && !file.inQueue) {
          rows.push({
            id: index,
            datasetname: file.name,
            numberModules:
              file.numberSelected +
              '/' +
              file.totalNumberModule +
              '  Optimizing',
            optimizationOptions: file.hardware + ' | ' + file.software,
            dateOptimized: file.optimizedDate,
            downloadOrTimer: '',
            logs: <div style={{ paddingLeft: '14px' }}>Optimizing...</div>,
            deleteOrSpinner: <Loading withOverlay={false} small={true} />,
          });
        } else if (file.optimizing && file.inQueue) {
          rows.push({
            id: index,
            datasetname: file.name,
            numberModules:
              file.numberSelected +
              '/' +
              file.totalNumberModule +
              ' modules selected',
            optimizationOptions: file.hardware + ' | ' + file.software,
            dateOptimized: file.optimizedDate,
            downloadOrTimer: '',
            logs: <div style={{ paddingLeft: '14px' }}>In Queue...</div>,
            deleteOrSpinner: <Loading withOverlay={false} small={true} />,
          });
        }
      });
    }

    const batchDeleteModalprops = () => ({
      className: 'IconDeleteModal',
      open: this.state.showBatchDeleteModal,
      passiveModal: false,
      danger: true,
      shouldSubmitOnEnter: false,
      modalHeading: 'Delete this data set?',

      primaryButtonText: 'Delete',
      secondaryButtonText: 'Close',
      onRequestClose: () => {
        this.setState({ showBatchDeleteModal: false });
      },
      onRequestSubmit: async () => {
        await this.props.handleDeleteOptimizedDataset();
        this.setState({ showBatchDeleteModal: false });
      },
    });

    return (
      <DataTable
        rows={rows}
        headers={headers}
        render={({ rows, headers, getHeaderProps, getRowProps }) => (
          <TableContainer>
            <Modal {...batchDeleteModalprops()}>
              <p className="bx--modal-content__text">
                This action cannot be undone. Are you sure you want to delete ?
              </p>
            </Modal>

            <Table size="normal">
              <TableHead>
                <TableRow>
                  {/* add the expand header before all other headers */}
                  <TableExpandHeader />

                  {headers.map(
                    header =>
                      header.key !== 'dropdown' && (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map(row => (
                    <React.Fragment key={row.id}>
                      <TableExpandRow {...getRowProps({ row })}>
                        {row.cells.map(
                          cell =>
                            !cell.id.includes('dropdown') && (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            )
                        )}
                      </TableExpandRow>

                      {/* toggle based off of if the row is expanded. If it is, render TableExpandedRow */}
                      {row.isExpanded && (
                        <TableExpandedRow colSpan={headers.length}>
                          {row.cells[7].value}
                        </TableExpandedRow>
                      )}
                    </React.Fragment>
                  ))}

                {rows.length === 0 && (
                  <TableRow key="0">
                    <TableCell key="0" colSpan="9">
                      <p className="paragraph_styles_no_item">
                        There are no optimized data sets available to be
                        downloaded
                      </p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
    );
  }
}

export default OptimizedDatasetTable;
