import React from 'react';
import {
  Button,
  Accordion,
  AccordionItem,
  CodeSnippet,
  Loading,
} from 'carbon-components-react';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../Optimizer/IdleTimeOutModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from 'actions';

const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};

const margin_top_20 = {
  marginTop: '20px',
};

const margin_top_10 = {
  marginTop: '10px',
};

const accordion_style = {
  width: '100%',
  marginTop: '20px',
};

const JCL1 = `
//ABOSEND JOB (YOUR JOB INFORMATON HERE)
//*-------------------------------------------------------------------
//* THE PURPOSE OF THIS JCL IS TO PACKAGE YOUR PRE-COMPILED COBOL
//* PROGRAM(S) INTO XMIT.TRS FORMAT TO UPLOAD TO THE IBM ABO TRIAL
//* CLOUD SERVICE
//*-------------------------------------------------------------------
//*-------------------------------------------------------------------
//* PLEASE SET THE JCL VARIABLES BELOW
//* 1) SET COBLOAD TO THE DATA SET YOU WANT TO SUBMIT FOR OPTIMIZATION
//*-------------------------------------------------------------------
// EXPORT SYMLIST=(COBLOAD)
// SET COBLOAD='HLQ.COBOL.PROG.DATASET.LOAD' <=PLEASE CHANGE
//*-------------------------------------------------------------------
//* JCL VARIABLE DEFAULTS, MAY NEED CHANGING IF DEFAULTS DON'T WORK
//*-------------------------------------------------------------------
// SET UNIT='SYSDA'
// SET SPACE='CYL,(5,5)'
//*-------------------------------------------------------------------
//* DELETE XMIT DATA SET IF IT ALREADY EXISTS
//*-------------------------------------------------------------------
//DEL01 EXEC PGM=IEFBR14
//DELDD1 DD DSN=&COBLOAD..XMIT,
// UNIT=&UNIT,SPACE=(TRK,1),DISP=(MOD,DELETE)
//*-------------------------------------------------------------------
//* CONVERT THE DATA SET TO XMIT FORMAT
//*-------------------------------------------------------------------
//XMIT EXEC PGM=IKJEFT01,
// REGION=0M
//SYSPRINT DD SYSOUT=*
//SYSTSPRT DD SYSOUT=*
//SYSUT2 DD DSN=&COBLOAD..XMIT,
// UNIT=&UNIT,SPACE=(&SPACE,RLSE),
// DCB=(RECFM=FB,LRECL=80,BLKSIZE=3120),
// DISP=(NEW,CATLG)
//SYSTSIN DD *,SYMBOLS=JCLONLY
XMIT X.Y -
DS('&COBLOAD') -
OUTDDNAME(SYSUT2)
/*
//*-------------------------------------------------------------------
//* DELETE TERSED DATA SET IF IT ALREADY EXISTS
//*-------------------------------------------------------------------
//DEL02 EXEC PGM=IEFBR14
//DELDD2 DD DSN=&COBLOAD..XMIT.TRS,
// UNIT=&UNIT,SPACE=(TRK,1),DISP=(MOD,DELETE)
//*-------------------------------------------------------------------
//* TERSE THE XMIT FILE
//*-------------------------------------------------------------------
//TERSE EXEC PGM=AMATERSE,PARM=PACK
//SYSPRINT DD SYSOUT=*
//SYSUT1 DD DSN=&COBLOAD..XMIT,
// DISP=SHR
//SYSUT2 DD DSN=&COBLOAD..XMIT.TRS,
// UNIT=&UNIT,SPACE=(&SPACE,RLSE),
// DISP=(NEW,CATLG,DELETE)
//*-------------------------------------------------------------------
                        `;

const FTPcomand = `
Use a graphical FTP client or use a command-line utility (shown below)
and ensure the transfer is done in BINARY.

FTP commands via UNIX command line are shown below.

FTP commands are interchangeable with the Windows command prompt.
Change to the directory that contains the tersed data set

$ ftp -i zos.host.id (or IP address of your z/OS machine)
$ bin (set transfer mode to BINARY)
$ cd // (set working directory prefix)
$ get data.set.to.be.uploaded.XMIT.TRS
$ quit
                        `;

const FTPcomand2 = `
Use a graphical FTP client or use a command-line utility (shown below) and ensure the transfer is
done in BINARY as well as maintaining allocation parameters.

FTP commands via UNIX command line shown below. FTP commands are interchangeable with the Windows
command prompt.

Change to the directory that you want to save the optimized TERSED data set

$ ftp -i zos.host.id (or IP address of your z/OS machine)
$ bin
$ quote site recfm=FB lrecl=1024
$ put BOZ.data.set.name.XMIT.TRS 'USERID.BOZ.DATA.SET.NAME.XMIT.TRS'
$ quit
`;

const JCL2 = `
//ABORECV JOB (YOUR JOB INFORMATON HERE)
//*-------------------------------------------------------------------
//* THE PURPOSE OF THIS JCL IS TO UNTERSE AND RECEIVE YOUR IBM ABO
//* OPTIMIZED COBOL PROGRAM(S) FOR EXECUTION ON Z/OS
//*-------------------------------------------------------------------
//*-------------------------------------------------------------------
//* PLEASE SET THE JCL VARIABLE BELOW
//* 1) SET‘ABOLOAD’TO THE OPTIMIZED TERSED DATA SET BUT REMOVE
//* THE ' .XMIT.TRS ' SUFFIX AS THE JCL WILL APPEND IT FOR YOU AS IT DOES
//* THE UNTERSE AND RECEIVE STEPS
//*-------------------------------------------------------------------
// EXPORT SYMLIST=(ABOLOAD)
// SET ABOLOAD='HLQ.DATASET.NAME' <=PLEASE CHANGE
//*-------------------------------------------------------------------
//* JCL VARIABLE DEFAULTS, MAY NEED CHANGING IF DEFAULTS DON'T WORK
//*-------------------------------------------------------------------
// SET UNIT='SYSDA'
// SET SPACE='CYL,(5,5)'
//*-------------------------------------------------------------------
//* DELETE XMIT DATA SET IF IT ALREADY EXISTS
//*-------------------------------------------------------------------
//DEL01 EXEC PGM=IEFBR14
//DELDD1 DD DSN=&ABOLOAD..XMIT,
// UNIT=&UNIT,SPACE=(TRK,1),DISP=(MOD,DELETE)
//*-------------------------------------------------------------------
//* UNTERSE A TERSED DATA SET
//*-------------------------------------------------------------------
//UNTERSE EXEC PGM=AMATERSE,PARM=UNPACK
//SYSPRINT DD SYSOUT=*
//SYSUT1 DD DSN=&ABOLOAD..XMIT.TRS,
// DISP=SHR
//SYSUT2 DD DSN=&ABOLOAD..XMIT,
// UNIT=&UNIT,SPACE=(&SPACE,RLSE),
// DCB=(RECFM=FB,LRECL=80,BLKSIZE=3120),
// DISP=(NEW,CATLG,DELETE)
//*-------------------------------------------------------------------
//* ISSUE THE RECEIVE COMMAND ON THE UNTERSED DATA SET TO
//* HAVE YOUR ABO OPTMIZED PROGRAM(S) READY FOR EXECUTION ON Z/OS.
//* THE OPTIMIZED COBOL PROGRAM(S) WILL BE IN A DATA SET NAMED WITH THE
//* SUPPLIED NAME IN VARIABLE ' ABOLOAD ' WITH SUFFIX ' BOZ.LOAD '.
//* YOU CAN CHANGE THE DATA SET NAME BY SETTING
//* DSNAME('HLQ.YOUR.OWN.DATASET.LOAD')
//*-------------------------------------------------------------------
//RECEIVE EXEC PGM=IKJEFT01,REGION=0M
//SYSTSPRT DD SYSOUT=*
//IDIOFF DD DUMMY
//SYSUT1 DD DSN=&ABOLOAD..XMIT,
// DISP=SHR
//SYSTSIN DD *,SYMBOLS=JCLONLY
RECEIVE INDDNAME(SYSUT1)
DSNAME('&ABOLOAD..BOZ.LOAD')
/*
//*-------------------------------------------------------------------
                        `;

let countdownInterval;
let timeout;

class Instruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      valueJCL1: JCL1,
      copiedJCL1: false,
      valueFTPCommand: FTPcomand,
      valueFTPCommand2: FTPcomand2,
      copiedFTP: false,
      copiedFTP2: false,
      valueJCL2: JCL2,
      copiedJCL2: false,
      openImage: false,
      timeout: 1000 * 60 * 25,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  //functions for handling session timeout
  //----------------------------------------------------

  clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    if (!this.state.showModal) {
      this.clearSessionInterval();
      this.clearSessionTimeout();
    }
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const delay = 1000 * 1 * 1;
    if (!this.state.showModal) {
      timeout = setTimeout(() => {
        let countDown = 60;
        this.setState({ showModal: true, timeoutCountdown: countDown });
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            this.setState({ timeoutCountdown: --countDown });
          } else {
            //this.props.history.push('/login');

            this.props.history.push({
              pathname: '/login',
              state: {
                showModal: true,
              },
            });
          }
        }, 1000);
      }, delay);
    }
  }

  handleClose() {
    this.clearSessionInterval();
    this.clearSessionTimeout();
    this.setState({ showModal: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.clearSessionInterval();
    this.clearSessionTimeout();
    //this.props.history.push('/login');
    window.location.href = '/appid/logout';
  }
  //----------------------------------------------------

  handleShowImage = () => {
    this.setState({ openImage: !this.state.openImage });
  };

  async componentDidMount() {
    //first initial fetch
    await this.props.checkAuth();

    //fetch data sets from the Cloudant database
    if (this.props.auth) {
      if (!this.props.agreement) {
        await this.props.fetchDataset(this.props.id);

        const userAgreement = await this.props.acceptUserAgreement;

        if (typeof userAgreement === 'undefined')
          this.props.history.push('/agreement');
        else if (userAgreement) {
          this.props.updateAgreement();
        }
      }
    } else {
      this.props.history.push('/login');
    }
  }

  render() {
    //console.log('instruction page this props agreement:', this.props.agreement);

    if (this.props.agreement) {
      return (
        <>
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />

          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            remainingTime={this.state.timeoutCountdown}
          />
          <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
            <div className="bx--row instruction_section">
              <div className="bx--col-md-4 bx--col-lg-7">
                <h4 className="instruction-page__heading">
                  Instructions for getting started with IBM Automatic Binary
                  Optimizer for z/OS Trial Cloud Service
                </h4>
                <p
                  className="paragraph_styles_justify"
                  style={{ marginTop: '20px' }}>
                  On this page, you will find instructions that explain how to
                  make the most of the ABO Trial Cloud Service. If your data
                  sets are ready, you can skip the instructions and navigate to
                  the Optimizer page to get started. If you do not have access
                  to a data set a sample data set can be downloaded{' '}
                  <a href="/api/download2/COMPTX.XMIT.TRS">here</a>.
                </p>

                <div className="paragraph_styles" style={margin_top_10}>
                  <a href="/api/download2/Tutorial_PDF.pdf" target="_blank">
                    Download Instructions as PDF
                  </a>
                </div>
              </div>
              <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8"></div>
            </div>

            <div
              className="bx--row identify_modules_section"
              style={{ marginTop: '20px' }}>
              <div className="bx--col-md-4 bx--col-lg-7">
                <h2 className="productive-heading-02">
                  Step 1. Identify modules
                </h2>
                <p
                  className="paragraph_styles_justify"
                  style={{ marginTop: '20px' }}>
                  When deciding which already-compiled modules to optimize with
                  ABO, pick the top CPU-consuming modules based on the following
                  criteria:
                  <ul
                    style={{
                      listStyleType: 'disc',
                      paddingTop: '10px',
                      paddingLeft: '20px',
                    }}>
                    <li style={margin_top_10}>
                      Programs that spend the most time in the COBOL application
                      code and select LE routines.
                    </li>
                    <li style={margin_top_10}>
                      Programs in which the COBOL code is performing a
                      nontrivial amount of work/computations; for example, a
                      program where the COBOL code itself is doing the actual,
                      real work, and is not simply acting as a "driver" program
                      for other programs or subsystems.
                    </li>
                    <li style={margin_top_10}>
                      At the source level, the statements most likely to benefit
                      include, but are not limited to: COMPUTE, IF, MOVE, ADD,
                      SUBTRACT, MULTIPLY, DIVIDE, EXPONENTIATION(**) and
                      REMAINDER.
                    </li>
                  </ul>
                </p>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  Recommended tools
                </h2>
                <div style={{ marginTop: '10px' }}>
                  <a
                    href="http://www-03.ibm.com/support/techdocs/atsmastr.nsf/WebIndex/PRS5132"
                    rel="noopener noreferrer"
                    target="_blank">
                    ZBNA
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://www.ibm.com/support/knowledgecenter/en/SSLTBW_2.4.0/com.ibm.zos.v2r4.erb/erb.htm"
                    rel="noopener noreferrer"
                    target="_blank">
                    RMF
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://www.ibm.com/products/application-performance-analyzer?mhsrc=ibmsearch_a&mhq=apa"
                    rel="noopener noreferrer"
                    target="_blank">
                    APA
                  </a>{' '}
                </div>

                <h2
                  style={{ paddingTop: '20px' }}
                  className="productive-heading-02">
                  Instructions
                </h2>

                <h2 style={margin_top_20} className="productive-heading-02">
                  1.1 Identify peak usage
                </h2>
                <p style={margin_top_10} className="paragraph_styles_justify">
                  Use a performance measurement tool to identify the time of the
                  day or the days of the month when CPU usage is the highest. We
                  recommend using IBM zBNA, a no charge, PC based productivity
                  tool to visualize CPU usage reports from SMF records.
                </p>

                <h2 style={margin_top_20} className="productive-heading-02">
                  1.2 Identify top job-program pairs
                </h2>
                <p style={margin_top_10} className="paragraph_styles_justify">
                  CPU usage reports can be generated based on the SMF Type 30
                  record. We recommend using IBM zBNA to isolate top CPU
                  consuming job-program pairs.
                </p>

                <h2 style={margin_top_20} className="productive-heading-02">
                  1.3 Identify top CPU consuming modules
                </h2>
                <p style={margin_top_10} className="paragraph_styles_justify">
                  Use a performance analysis tool such as IBM Application
                  Performance Analyzer (APA) to identify the top CPU consuming
                  modules and CSECTs from the job-program pairs.
                </p>

                <h2 style={margin_top_20} className="productive-heading-02">
                  1.4 Scan for COBOL CSECTs
                </h2>
                <p style={margin_top_10} className="paragraph_styles_justify">
                  Scan for COBOL CSECTs and identify the CSECTs eligible for
                  optimization in the top modules. IBM File Manager can be used
                  to do this. The top contributing modules might or might not
                  contain COBOL CSECTs that are eligible for optimization.
                </p>
              </div>
              <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                <div className="video-responsive" style={{ marginTop: '20px' }}>
                  <iframe
                    title="step 1 video"
                    //width="640"
                    //height="380"
                    //width="100%"
                    //height="100%"
                    src="https://cdnapisec.kaltura.com/p/1773841/sp/177384100/embedIframeJs/uiconf_id/44837961/partner_id/1773841?iframeembed=true&playerId=kplayer&entry_id=1_6wnitauj&flashvars[streamerType]=auto"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>

            <div
              className="bx--row prepare_datasets_section"
              style={{ marginTop: '20px' }}>
              <div className="bx--col-md-4 bx--col-lg-7">
                <h2 className="productive-heading-02">
                  Step 2. Prepare data sets
                </h2>
                <p
                  className="paragraph_styles_justify"
                  style={{ marginTop: '20px' }}>
                  To prepare the data sets to be optimized by the ABO Trial
                  Cloud Service, use z/OS to convert the data sets into XMIT
                  format and then TERSE terse them. You can then transfer the
                  data sets to your preferred workstation. A maximum of{' '}
                  <b style={{ fontWeight: 'bold' }}>500 modules </b> are
                  permitted per data set, and a maximum of{' '}
                  <b style={{ fontWeight: 'bold' }}>20 data sets </b> can be
                  stored on the trial at one time.
                </p>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  Instructions
                </h2>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  2.1 Convert data sets to XMIT format and TERSE them
                </h2>

                <p style={margin_top_20} className="paragraph_styles_justify">
                  On z/OS, convert the chosen data sets that contain your
                  original program modules to XMIT format and then TERSE the
                  XMIT files. Data sets must follow the naming convention of
                  data.set.name.XMIT.TRS. The '.XMIT' extension is mandatory
                  when you XMIT the data sets prior to AMATERSE and the '.TRS'
                  extension is mandatory for the final TERSED data set. Find the
                  JCL code needed to perform this step below in the dropdown:
                </p>

                <Accordion>
                  <AccordionItem title="Sample JCL" style={accordion_style}>
                    <div className="">
                      <CopyToClipboard
                        text={this.state.valueJCL1}
                        onCopy={() => this.setState({ copiedJCL1: true })}>
                        <CodeSnippet
                          type="multi"
                          onClick={() => this.setState({ copiedJCL1: true })}>
                          {this.state.valueJCL1}
                        </CodeSnippet>
                      </CopyToClipboard>
                    </div>
                  </AccordionItem>
                </Accordion>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  2.2 Transfer the TERSED data set from your z/OS machine to
                  your PC workstation
                </h2>

                <p style={margin_top_20} className="paragraph_styles_justify">
                  Use FTP to transfer the data set to your workstation
                  environment to upload them to the ABO Trial Cloud Service.
                  Find the FTP commands below in the dropdown:
                </p>

                <Accordion>
                  <AccordionItem style={accordion_style} title="FTP commands">
                    <div>
                      <CopyToClipboard
                        text={this.state.valueFTPCommand}
                        onCopy={() => this.setState({ copiedFTP: true })}>
                        <CodeSnippet
                          type="multi"
                          onClick={() => this.setState({ copiedFTP: true })}>
                          {this.state.valueFTPCommand}
                        </CodeSnippet>
                      </CopyToClipboard>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                <div className="video-responsive" style={{ marginTop: '20px' }}>
                  <iframe
                    title="step 2 video"
                    //width="640"
                    //height="380"
                    //width="100%"
                    //height="100%"
                    src="https://cdnapisec.kaltura.com/p/1773841/sp/177384100/embedIframeJs/uiconf_id/44837961/partner_id/1773841?iframeembed=true&playerId=kplayer&entry_id=1_txk35bnk&flashvars[streamerType]=auto"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>

            <div
              className="bx--row go_to_optimize_section"
              style={{ marginTop: '20px' }}>
              <div className="bx--col-md-4 bx--col-lg-7">
                <h2 className="productive-heading-02">
                  Step 3. Optimize modules
                </h2>
                <p style={margin_top_20} className="paragraph_styles_justify">
                  Navigate to the{' '}
                  <b style={{ fontWeight: 'bold' }}>Optimizer </b> tab in the
                  navigation bar above or click the{' '}
                  <b style={{ fontWeight: 'bold' }}>Go to optimizer </b>
                  button below to start optimizing your modules.
                </p>
                <div style={margin_top_20}>
                  <Button
                    onClick={() => {
                      this.props.history.push('/optimizer');
                    }}>
                    Go to optimizer
                  </Button>
                </div>
              </div>
              <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8"></div>
            </div>

            <div
              className="bx--row transfer_dataset_section"
              style={{ marginTop: '20px' }}>
              <div className="bx--col-md-4 bx--col-lg-7">
                <h2 className="productive-heading-02">
                  Step 4. Transfer optimized data sets
                </h2>
                <p
                  className="paragraph_styles_justify"
                  style={{ marginTop: '20px' }}>
                  Download the optimized zip file package from the ABO Trial
                  Cloud Service and transfer data sets back to your z/OS machine
                  for further testing. All data sets will be automatically
                  deleted from the ABO Trial Cloud Service at the end of the 90
                  day evaluation period. Clients will be notified via email of
                  the end of the evaluation period, and use of the service will
                  be disabled. IBM does retain the optimization, scanning, and
                  listing transform log files to help improve IBM Automatic
                  Binary Optimizer for z/OS.
                </p>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  Instructions
                </h2>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  4.1 Transfer the optimized TERSED data set back to the z/OS
                  machine
                </h2>

                <p style={margin_top_20} className="paragraph_styles_justify">
                  Use FTP to transfer the TERSED data set that contains the ABO
                  optimized program module to your z/OS machine. Find the FTP
                  command line sample below in the dropdown:
                </p>

                <Accordion>
                  <AccordionItem style={accordion_style} title="FTP commands">
                    <div>
                      <CopyToClipboard
                        text={this.state.valueFTPCommand2}
                        onCopy={() => this.setState({ copiedFTP2: true })}>
                        <CodeSnippet
                          type="multi"
                          onClick={() => this.setState({ copiedFTP2: true })}>
                          {this.state.valueFTPCommand2}
                        </CodeSnippet>
                      </CopyToClipboard>
                    </div>
                  </AccordionItem>
                </Accordion>

                <p style={margin_top_20} className="paragraph_styles_justify">
                  Optimized TERSED data sets need to be uploaded to the z/OS
                  machine in the following format:
                  <ul style={margin_top_20}>
                    <li>- LRECL: 1024</li>
                    <li>- RECFM: FB</li>
                    <li>- BLOCKSIZE: 1024</li>
                  </ul>
                </p>

                <h2 style={margin_top_20} className="productive-heading-02">
                  4.2 UNTERSE and convert data set from the XMIT format to its
                  original state
                </h2>

                <p style={margin_top_20} className="paragraph_styles_justify">
                  UNTERSE and receive (convert from XMIT.TRS format) the ABO
                  program modules for execution on z/OS. Find the JCL code
                  needed to perform this step below in the dropdown:
                </p>

                <Accordion>
                  <AccordionItem style={accordion_style} title="Sample JCL">
                    <div>
                      <CopyToClipboard
                        text={this.state.valueJCL2}
                        onCopy={() => this.setState({ copiedJCL2: true })}>
                        <CodeSnippet
                          type="multi"
                          onClick={() => this.setState({ copiedJCL2: true })}>
                          {this.state.valueJCL2}
                        </CodeSnippet>
                      </CopyToClipboard>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                <div className="video-responsive" style={{ marginTop: '20px' }}>
                  <iframe
                    title="step 4 video"
                    //width="640"
                    //height="380"
                    //width="100%"
                    //height="100%"
                    src="https://cdnapisec.kaltura.com/p/1773841/sp/177384100/embedIframeJs/uiconf_id/44837961/partner_id/1773841?iframeembed=true&playerId=kplayer&entry_id=1_govzk3pq&flashvars[streamerType]=auto"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>

            <div
              className="bx--row test_module_performance_section"
              style={{ marginTop: '20px' }}>
              <div className="bx--col-md-4 bx--col-lg-7">
                <h2 className="productive-heading-02">
                  Step 5. Test module performance
                </h2>
                <p
                  className="paragraph_styles_justify"
                  style={{ marginTop: '20px' }}>
                  To measure the benefit from ABO, compare the CPU time taken
                  using the original modules and the time taken using the ABO
                  optimized modules. Follow these best practices to ensure the
                  accuracy of your measurements:
                  <ul
                    style={{
                      listStyleType: 'disc',
                      paddingTop: '10px',
                      paddingLeft: '20px',
                    }}>
                    <li>
                      Conduct the performance test in a controlled environment;
                      for example, in a test system.
                    </li>
                    <li style={margin_top_10}>
                      Use the exact same input test data for the original and
                      ABO optimized runs. Ensure that the system/LPAR is as
                      quiet as possible for stable and reproducible numbers.
                    </li>
                    <li style={margin_top_10}>
                      If possible, perform more than one run using the original
                      and ABO optimized modules to understand the normal
                      variability or noise when running the application.
                    </li>
                  </ul>
                </p>

                <h2
                  style={{ marginTop: '20px' }}
                  className="productive-heading-02">
                  Example
                </h2>

                <p style={margin_top_20} className="paragraph_styles_justify">
                  The following example shows a snippet from a JES2 job log
                  after the execution of the same application in two separate
                  job steps. The CPU Time and Service Units data from the job
                  log is used to compare the performance of this application
                  using the original COBOL modules (ORIGGO job step) to the same
                  application using the ABO optimized modules (ABOGO job step)
                  <ul
                    style={{
                      listStyleType: 'disc',
                      paddingTop: '10px',
                      paddingLeft: '20px',
                    }}>
                    <li>
                      If you look at the CPU (Total) column, the ORIGGO step
                      took 3.44 CPU seconds, while the ABOGO step took only 1.14
                      CPU seconds
                    </li>
                    <li style={margin_top_10}>
                      Similarly, looking at the Service Units column, the ORIGGO
                      step used 2116K Service Units, while the ABOGO step used
                      only 700K Service Units
                    </li>
                    <li style={margin_top_10}>
                      In this example over three times less CPU time and Service
                      Units are required when using the ABO optimized modules
                    </li>
                  </ul>
                </p>

                <img
                  id="myImg"
                  className="performance_image"
                  src={
                    process.env.PUBLIC_URL + '/img/test-performance-image.png'
                  }
                  alt="Test module performance"
                  onClick={this.handleShowImage}
                />

                {/*{this.state.openImage && <div id="myModal" class="modal"></div>}*/}
              </div>
              <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                <div className="video-responsive" style={{ marginTop: '20px' }}>
                  <iframe
                    title="step 5 video"
                    //width="640"
                    //height="380"
                    //width="100%"
                    //height="100%"
                    src="https://cdnapisec.kaltura.com/p/1773841/sp/177384100/embedIframeJs/uiconf_id/44837961/partner_id/1773841?iframeembed=true&playerId=kplayer&entry_id=1_hz6opv7r&flashvars[streamerType]=auto"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <Loading
        active
        className="landing_page_loading_icon"
        description="Active loading indicator"
        small={false}
        withOverlay={false}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: () => dispatch(actions.checkAuth()),
    fetchDataset: id => dispatch(actions.fetchDatasets(id)),
    dispatch,
  };
};

function mapStateToProps(state) {
  return {
    id: state.auth.id,
    auth: state.auth.isAuth,
    name: state.auth.name,
    email: state.auth.email,
    acceptUserAgreement: state.datasets.data[2],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Instruction)
);
