/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import {
  FETCH_DATASETS_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  FETCH_DATASETS_FAIL,
  FETCH_DATASETS_INIT,
} from './types';

import axios from 'axios';
import axiosService from 'services/axios-service';
//import { routerMiddleware, push } from 'react-router-redux';

//axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
//axios.defaults.xsrfCookieName = "csrftoken";

//create axiosService getInstance
const axiosInstance = axiosService.getInstance();

//function dataset action
export const fetchDatasets = userid => {
  const url = `/datasets?userid=${userid}`;
  //console.log('actions fetch dataset username:', user);
  return dispatch => {
    //always set to the intial state in main page
    dispatch(featchDatasetsInit());

    return axiosInstance
      .get(url)
      .then(res => {
        return res.data;
      })
      .then(datasets => {
        dispatch(featchDatasetsSuccess(datasets));
      })
      .catch(error => {
        dispatch(featchDatasetsFail(error.response));
      });
  };
};

const featchDatasetsInit = () => {
  return {
    type: FETCH_DATASETS_INIT,
  };
};

const featchDatasetsFail = err => {
  return {
    type: FETCH_DATASETS_FAIL,
    errors: err,
  };
};

const featchDatasetsSuccess = datatsets => {
  return {
    type: FETCH_DATASETS_SUCCESS,
    datasets: datatsets,
  };
};

// USER User Authorization and Registration ACTIONS ---------------------------

export const checkAuth = () => {
  return dispatch => {
    return axios
      .get('/api/idPayload')
      .then(response => {
        //console.log('response:', response.data);
        //console.log(response);

        localStorage.setItem('auth_token', response.data.token);

        dispatch({
          type: LOGIN_SUCCESS,
          token: response.data.token,
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
        });
      })
      .catch(error => {
        dispatch({
          type: LOGIN_FAILURE,
          errors: error,
        });
      });
  };
};

// datasets Creation ACTIONS ---------------------------

export const updateAvailableDataset = data => {
  return axiosInstance
    .post('/datasets/updateAvailableDataset', { ...data })
    .then(
      //when success
      res => {
        //return res.data;
        return { data: res.data };
      },
      //when fail
      err => {
        //console.log('updateAvailableDataset fail:' + err);
        return Promise.reject(err.response.data.errors);
      }
    );
};

export const updateOpitmizedDataset = data => {
  return axiosInstance
    .post('/datasets/updateOpitmizedDataset', { ...data })
    .then(
      //when success
      res => {
        //return res.data;
        return { data: res.data };
      },
      //when fail
      err => {
        //console.log('updateOpitmizedDataset fail:' + err);
        return Promise.reject(err.response.data.errors);
      }
    );
};

export const uploadDataset = data => {
  return axiosInstance.post('/datasets/upload', { ...data }).then(
    //when success
    res => {
      //return res.data;
      return { data: res.data };
    },
    //when fail
    err => {
      //console.log('upload data set fail:' + err);
      return Promise.reject(err.response.data.errors);
    }
  );
};

export const opitmizeDataset = data => {
  return axiosInstance.post('/datasets/opitmizeDataset', { ...data }).then(
    //when success
    res => {
      //return res.data;
      return { data: res.data };
    },
    //when fail
    err => {
      //console.log('create data set fail:' + err);
      console.log('opitimize dataset error in action creator:' + err);

      return Promise.reject(err.response.data.errors);
    }
  );
};

export const createProfile = data => {
  return axiosInstance.post('/datasets/createProfile', { ...data }).then(
    //when success
    res => {
      //return res.data;
      return { data: res.data };
    },
    //when fail
    err => {
      //console.log('create profile fail:' + err);
      return Promise.reject(err.response.data.errors);
    }
  );
};

//Upload file
export const uploadFile = file => {
  //console.log('action upload file:');

  //passing in a formdata for image
  const formData = new FormData();
  formData.append('file', file);

  file.map(item => {
    formData.append('file', item);
  });

  //axios post request to upload image to server
  return axiosInstance
    .post('/file-upload', formData)
    .then(
      //when success
      res => {
        return { data: res.data };
      }
    )
    .catch(err => {
      return Promise.reject(err.response.data.errors[0]);
    });
};

//delete a file in cloud object storage
export const deleteFile = file => {
  return axiosInstance
    .post('/file-delete', { ...file })
    .then(
      //when success
      res => {
        return { data: res.data };
      }
    )
    .catch(err => {
      //when fail
      return Promise.reject(err.response.data.errors);
    });
};
