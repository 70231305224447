/*
IBM Confidential
IBM Automatic Binary Optimizer for z/OS Trial Cloud Service
Copyright IBM Corp. 2020
The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

*/

import React from 'react';
import { connect } from 'react-redux';

import {
  DataTable,
  Button,
  Link,
  Select,
  SelectItem,
  Modal,
} from 'carbon-components-react';

import IconLeftArrow from '@carbon/icons-react/lib/arrow--left/24';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarSearch,
  TableToolbarContent,
  TableSelectAll,
  TableSelectRow,
} = DataTable;

class SelectModulesTable extends React.Component {
  //constructor
  constructor(props) {
    super(props);

    this.state = {
      datasetname:
        typeof this.props.currentDataset === 'undefined'
          ? ''
          : this.props.currentDataset.name,
      hardwareVersion: '',
      softwareVersion: '',
      showWarningHardware: false,
      showWarningSoftware: false,
      showWarningSelectModule: false,
      selectedRows: [],
      selectedAll: true,
      numberSelected:
        typeof this.props.currentDataset === 'undefined'
          ? 0
          : this.props.currentDataset.numberSelected,
      totalNumberModule:
        typeof this.props.currentDataset === 'undefined'
          ? 0
          : this.props.currentDataset.totalNumberModule,
      showModal: false,
    };

    //this.child = React.createRef();
  }
  isEmpty = str => {
    return !str || 0 === str.length;
  };

  handleSelectHSoftware = selectedItem => {
    //console.log(selectedItem);

    this.setState({
      softwareVersion: selectedItem,
      showWarningSoftware: false,
    });
  };

  handleSelectHardware = selectedItem => {
    //console.log(selectedItem);

    this.setState({
      hardwareVersion: selectedItem,
      showWarningHardware: false,
    });
  };

  //check for warning
  checkForWarning = () => {
    if (
      this.isEmpty(this.state.hardwareVersion) &&
      this.isEmpty(this.state.softwareVersion)
    ) {
      this.setState({
        showWarningHardware: true,
        showWarningSoftware: true,
        showModal: false,
      });
    } else if (this.isEmpty(this.state.softwareVersion)) {
      this.setState({
        showWarningSoftware: true,
        showModal: false,
      });
    } else if (this.isEmpty(this.state.hardwareVersion)) {
      this.setState({
        showWarningHardware: true,
        showModal: false,
      });
    } else if (this.props.currentDataset.numberSelected === 0) {
      this.setState({
        showWarning: true,
        showModal: false,
      });
    } else {
      this.setState({
        showModal: true,
      });
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  //user click optimized button
  OptimizeButton = () => {
    if (this.isEmpty(this.state.hardwareVersion)) {
      this.setState({
        showWarningHardware: true,
      });
    } else if (this.isEmpty(this.state.softwareVersion)) {
      this.setState({
        showWarningSoftware: true,
      });
    } else if (this.props.currentDataset.numberSelected === 0) {
      this.setState({
        showWarning: true,
      });
    } else {
      const { currentDataset } = this.props;

      const datasetInfo = {
        id: 0,
        name: currentDataset.name,
        hardware: this.state.hardwareVersion,
        software: this.state.softwareVersion,
        available: true,
        optimizing: true,
        optimized: false,
        inQueue: true,
        modules: currentDataset.modules,
        totalNumberModule: currentDataset.totalNumberModule,
        numberSelected: currentDataset.numberSelected,
        optimizedDate: '',
        moduleListAvailable: currentDataset.moduleListAvailable,
        errors: currentDataset.errors,
        status: currentDataset.status,
        fileUploadStatus: currentDataset.fileUploadStatus,
        showCompletedNotification: currentDataset.showCompletedNotification,
        showOptimizedCompletedNotification: false,
        optimizedSucceedNumber: 0,
        downloadFileName: '',
        summaryFileName: '',
      };

      this.setState({
        hardwareVersion: '',
        softwareVersion: '',
      });

      //this.props.clickOptimizeButton(datasetInfo);

      this.props.clickOptimizeButton(datasetInfo);
    }
  };

  poolSelectedHandler = (selectedRows, allRows) => {
    const currentModule =
      typeof this.props.currentDataset === 'undefined'
        ? []
        : this.props.currentDataset.modules;

    const currentModuleName =
      typeof this.props.currentDataset === 'undefined'
        ? ''
        : this.props.currentDataset.name;

    let items = [...currentModule];

    allRows.map(item => {
      items[item.id].isSelected = item.isSelected;
    });

    this.props.modulesSelectedOnSelectModuleTable(
      selectedRows.length,
      currentModuleName,
      items
    );
  };

  render() {
    const rows = [];
    let currentName = 'test';

    if (this.props.currentDataset) {
      this.props.currentDataset.modules.map((file, index) => {
        rows.push({
          id: file.id,
          moduleName: file.moduleName,
          isSelected: file.isSelected,
        });
      });

      currentName =
        typeof this.props.currentDataset === 'undefined'
          ? 'test'
          : this.props.currentDataset.name;
    }

    // We would have a headers array like the following
    const headers = [
      {
        key: 'id',
        header: '',
      },
      {
        key: 'moduleName',
        header: 'Module name',
      },
    ];

    let errorMsgSoftware = '';
    let errorMsgHardware = '';
    let invalidSelectSoftware = false;
    let invalidSelectHardware = false;

    if (this.state.showWarningHardware && this.state.showWarningSoftware) {
      invalidSelectHardware = true;
      errorMsgHardware = 'Please select a hardware version';
      invalidSelectSoftware = true;
      errorMsgSoftware = 'Please select a software version';
    } else if (this.state.showWarningHardware) {
      invalidSelectHardware = true;
      errorMsgHardware = 'Please select a hardware version';
    } else if (this.state.showWarningSoftware) {
      invalidSelectSoftware = true;
      errorMsgSoftware = 'Please select a software version';
    }

    let disableOptimizeButton = this.props.disableOpitmizationDueToExpiry;
    //this.props.showOptimizingNotificationAvailableDataset ||
    //this.props.disableOpitmizationDueToExpiry;

    return (
      <div className="bx--grid bx--grid--no-gutter bx--grid--full-width select_module_section">
        <div className="bx--row" style={{ paddingTop: '2rem' }}>
          <div className="bx--col-lg-8">
            <a
              title="Go back"
              href="#"
              style={{ paddingRight: '5px' }}
              onClick={() => {
                this.props.showSelectModuleTableButtonDisable();
              }}>
              <IconLeftArrow fill="blue" />

              <Link
                href="#"
                className="back_to_optimizer_link"
                onClick={() => {
                  this.props.showSelectModuleTableButtonDisable();
                }}>
                Go back
              </Link>
            </a>
          </div>
          <div className="bx--col-lg-8"></div>
        </div>

        <div className="bx--row select_module_table_content">
          <div className="bx--col-lg-8">
            <DataTable
              rows={rows}
              headers={headers}
              render={({
                rows,
                headers,
                getHeaderProps,
                getSelectionProps,
                onInputChange,
                selectedRows,
                selectRow,
                selectAll,
              }) => (
                <TableContainer
                  title={currentName}
                  className="selectModuleTableContainer">
                  {this.poolSelectedHandler(selectedRows, rows)}

                  <div className="select_version_div">
                    <Select
                      hideLabel
                      className="selection_hardware_class"
                      defaultValue="placeholder-item"
                      disabled={false}
                      iconDescription="open list of options"
                      id="select-1"
                      inline={false}
                      invalid={invalidSelectHardware}
                      invalidText={errorMsgHardware}
                      light={false}
                      onChange={event => {
                        this.handleSelectHardware(
                          event.target.options[
                            event.target.options.selectedIndex
                          ].text
                        );
                      }}>
                      <SelectItem
                        disabled
                        hidden
                        text="Select hardware version"
                        value="placeholder-item"
                      />
                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z16"
                        value="z16"
                      />
                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z15/z15 T02"
                        value="z15/z15 T02"
                      />
                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z14/z14 ZR1"
                        value="z14/z14 ZR1"
                      />
                    </Select>

                    <Select
                      hideLabel
                      className="selection_software_class"
                      defaultValue="placeholder-item"
                      disabled={false}
                      iconDescription="open list of options"
                      id="select-2"
                      inline={false}
                      invalid={invalidSelectSoftware}
                      invalidText={errorMsgSoftware}
                      light={false}
                      onChange={event => {
                        this.handleSelectHSoftware(
                          event.target.options[
                            event.target.options.selectedIndex
                          ].text
                        );
                      }}>
                      <SelectItem
                        disabled
                        hidden
                        text="Select software version"
                        value="placeholder-item"
                      />

                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z/OS 3.1"
                        value="z/OS 3.1"
                      />
                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z/OS 2.5"
                        value="z/OS 2.5"
                      />
                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z/OS 2.4"
                        value="z/OS 2.4"
                      />
                      <SelectItem
                        disabled={false}
                        hidden={false}
                        text="z/OS 2.3"
                        value="z/OS 2.3"
                      />
                    </Select>
                  </div>

                  <div
                    style={{
                      marginTop: '2rem',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}>
                    {selectedRows.length}/{this.state.totalNumberModule}{' '}
                    selected
                  </div>

                  {this.state.showWarning && selectedRows.length === 0 && (
                    <div>
                      <p
                        style={{
                          marginLeft: '10px',
                          color: 'red',
                          textAlign: 'left',
                        }}>
                        Please select modules to optimize
                      </p>
                    </div>
                  )}

                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch
                        persistent
                        className="searchBar-selectTable"
                        placeHolderText="Filter modules"
                        onChange={onInputChange}
                      />

                      <Button
                        disabled={disableOptimizeButton}
                        onClick={this.checkForWarning}
                        kind="primary"
                        className="opitmize_buttom_class">
                        Optimize
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <div className="table-wrapper-scroll-y my-custom-scrollbar">
                    <Table
                      className="selectModuleTable"
                      useZebraStyles={true}
                      size="compact"
                      stickyHeader={false}>
                      <TableHead>
                        <TableRow>
                          <TableSelectAll
                            {...getSelectionProps({ rows })}
                            onSelect={() => {
                              if (
                                rows.length ===
                                this.props.currentDataset.modules.length
                              ) {
                                selectAll();
                              } else {
                                rows.map(row => {
                                  selectRow(row.id);
                                });
                              }
                            }}
                          />
                          {headers.map(header => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map(row => (
                          <TableRow key={row.id}>
                            <TableSelectRow
                              {...getSelectionProps({ row })}
                              onSelect={() => {
                                selectRow(row.id);
                              }}
                            />

                            {row.cells.map(cell => (
                              <TableCell key={cell.id}>
                                {cell.id.includes('id') ? (
                                  <div>{cell.value + 1}</div>
                                ) : (
                                  cell.value
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              )}
            />
          </div>
          <div className="bx--col-lg-8"></div>
        </div>

        <Modal
          className="IconDeleteModal"
          hasScrollingContent={false}
          focusTrap={false}
          iconDescription="Close the modal"
          modalAriaLabel="A label to be read by screen readers on the modal root node"
          modalHeading="Confirm Optimization"
          onBlur={function noRefCheck() { }}
          onClick={function noRefCheck() { }}
          onFocus={function noRefCheck() { }}
          onKeyDown={function noRefCheck() { }}
          onRequestClose={this.closeModal}
          onRequestSubmit={this.OptimizeButton}
          onSecondarySubmit={this.closeModal}
          open={this.state.showModal}
          passiveModal={false}
          primaryButtonDisabled={false}
          primaryButtonText="Optimize"
          secondaryButtonText="Cancel"
          selectorPrimaryFocus="[data-modal-primary-focus]">
          <p className="bx--modal-content__text">
            Optimization of {currentName} may take several hours, and cannot be
            cancelled mid-optimization. You will receive an email notification
            once your data set has been optimized.
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  //debugger;
  return {
    datasets: state.datasets.data[0],
    optimizedDatasets: state.datasets.data[1],
    token: state.auth.token,
    id: state.auth.id,
    name: state.auth.name,
    acceptUserAgreement: state.datasets.data[2],
    fileArrayHistory: state.datasets.data[3],
    lastOpitmizedDatasetName: state.datasets.data[4],
    lastOpitmizedSuccessNumber: state.datasets.data[5],
    startTrialDate: state.datasets.data[6],
    expiryDateFormat: state.datasets.data[7],
    optimizingDatasetName: state.datasets.data[8],
    notifications: state.datasets.data[9],
    maintenance: state.datasets.data[10],
  };
};

export default connect(mapStateToProps)(SelectModulesTable);
