import React from 'react';
import { Button, Modal } from 'carbon-components-react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from 'actions';

import IconRightArrow from '@carbon/icons-react/lib/arrow--right/20';

const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};

const footerStyle = {
  backgroundColor: '$ui-01',
  fontSize: '20px',
  color: 'white',
  borderTop: '1px solid #E7E7E7',
  textAlign: 'center',
  padding: '20px',
  position: 'fixed',
  left: '0',
  bottom: '0',
  height: '60px',
  width: '100%',
};

const phantomStyle = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
};
class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    this.props.checkAuth();

    if (this.props.location.state && this.props.location.state.showModal) {
      this.setState({ showModal: this.props.location.state.showModal });
      window.history.replaceState(null, '');
    }
  }

  handleClose = () => {
    this.setState({ showModal: false });
    window.history.replaceState(null, '');
    window.location.href = '/appid/logout';
  };

  render() {
    return (
      <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
        <div className="bx--row landing-page__tab-content">
          <div className="bx--col-md-4 bx--col-lg-7">
            <h2 className="landing-page__subheading">
              Welcome to the IBM Automatic Binary Optimizer for z/OS Trial Cloud
              Service
            </h2>
            <p
              className="paragraph_styles_justify"
              style={{ marginTop: '20px' }}>
              The IBM Automatic Binary Optimizer for z/OS (ABO) Trial Cloud
              Service allows you to see the performance benefits of running
              ABO-optimized programs in your environment without having to
              install the on-premise ABO trial version on your system. Upload
              your fully or partially bound program modules to the ABO Trial
              Cloud Service and ABO will automatically scan them and optimize
              the eligible COBOL programs. Additional performance features are
              available when you use the fully licensed version or the
              on-premise trial version of ABO.
            </p>

            <h2 className="productive-heading-02" style={{ marginTop: '30px' }}>
              Programs eligible for optimization
            </h2>

            <p style={{ marginTop: '10px' }} className="paragraph_styles">
              A program is eligible for input to ABO if all of the following
              conditions apply:
            </p>

            <ul
              style={{
                listStyleType: 'disc',
                paddingTop: '10px',
                paddingLeft: '20px',
              }}>
              <li className="paragraph_styles">
                The program was generated by an eligible COBOL compiler.
              </li>
              <li className="paragraph_styles">
                All the language features are supported by ABO.
              </li>
              <li className="paragraph_styles">
                All optimization verification passes succeed.
              </li>
            </ul>

            <p style={{ marginTop: '20px' }} className="paragraph_styles">
              After validating the input program, ABO processes it and produces
              a functionally equivalent executable program that is optimized to
              target the latest IBM Z mainframes.
            </p>

            <h2 className="productive-heading-02" style={{ marginTop: '20px' }}>
              Software and hardware requirements
            </h2>

            <p style={{ marginTop: '20px' }} className="paragraph_styles">
              The ABO optimized programs can be run on:
            </p>

            <ul
              style={{
                listStyleType: 'disc',
                paddingLeft: '20px',
              }}>
              <li className="paragraph_styles">z/OS 2.3 to z/OS 3.1</li>
              <li className="paragraph_styles">IBM z14/z14 Model ZR1 to IBM z16</li>
            </ul>

            <h2
              style={{
                marginTop: '40px',
              }}
              className="productive-heading-02">
              Steps overview
            </h2>

            <h2
              style={{
                marginTop: '20px',
              }}
              className="productive-heading-02">
              1. Identify modules
            </h2>
            <p style={{ marginTop: '10px' }} className="paragraph_styles">
              Using tools such as zBNA, you can identify peak usage times,
              identify top job-program pairs, identify the top CPU consuming
              modules, and then scan for COBOL CSECTs.{' '}
              <b style={{ fontWeight: 'bold' }}>
                It is recommended this step be completed before beginning the
                trial.
              </b>
              <br />
              <div className="paragraph_styles">
                Video:{' '}
                <a
                  href="https://mediacenter.ibm.com/media/t/1_6wnitauj"
                  rel="noopener noreferrer"
                  target="_blank">
                  Identifying modules for optimization
                </a>
              </div>
            </p>

            <h2
              style={{
                marginTop: '20px',
              }}
              className="productive-heading-02">
              Recommended tools
            </h2>
            <div className="paragraph_styles">
              <a
                href="https://www-03.ibm.com/support/techdocs/atsmastr.nsf/WebIndex/PRS5132"
                rel="noopener noreferrer"
                target="_blank">
                zBNA
              </a>{' '}
              |{' '}
              <a
                href="https://www.ibm.com/support/knowledgecenter/SSLTBW_2.4.0/com.ibm.zos.v2r4.e0ze100/rmf.htm"
                rel="noopener noreferrer"
                target="_blank">
                RMF
              </a>{' '}
              |{' '}
              <a
                href="https://www.ibm.com/products/application-performance-analyzer?mhsrc=ibmsearch_a&mhq=apa"
                rel="noopener noreferrer"
                target="_blank">
                APA
              </a>
            </div>

            <h2
              style={{
                marginTop: '20px',
              }}
              className="productive-heading-02">
              2. Prepare datasets
            </h2>
            <p className="paragraph_styles" style={{ marginTop: '10px' }}>
              To prepare the data sets to be optimized by the ABO Trial Cloud
              Service, convert the data sets into XMIT format and then TERSE
              them. You can then transfer the data sets to your preferred
              workstation.
            </p>

            <h2
              style={{
                marginTop: '20px',
              }}
              className="productive-heading-02">
              3. Optimize modules
            </h2>
            <p className="paragraph_styles" style={{ marginTop: '10px' }}>
              Using the ABO Trial Cloud Service interface, you can upload the
              TERSED data sets, scan them to identify eligible COBOL modules,
              and optimize the modules. Optimized modules are packaged as a ZIP
              file and can then be downloaded.
            </p>

            <h2
              style={{
                marginTop: '20px',
              }}
              className="productive-heading-02">
              4. Transfer optimized data sets
            </h2>
            <p className="paragraph_styles" style={{ marginTop: '10px' }}>
              After downloading the optimized ZIP file package from the ABO
              Trial Cloud Service, transfer the data sets back to your z/OS
              machine for performance testing and evaluation.
            </p>

            <h2
              style={{
                marginTop: '20px',
              }}
              className="productive-heading-02">
              5. Test module performance
            </h2>
            <p className="paragraph_styles" style={{ marginTop: '10px' }}>
              To measure the performance benefits from ABO, compare the CPU time
              taken using the original modules and the time taken using the ABO
              optimized modules.
            </p>
          </div>
          <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
            <div className="video-responsive">
              <iframe
                title="intruction video"
                //width="640"
                //height="380"
                //width="100%"
                //height="100%"
                src="https://cdnapisec.kaltura.com/p/1773841/sp/177384100/embedIframeJs/uiconf_id/44837961/partner_id/1773841?iframeembed=true&playerId=kplayer&entry_id=1_h6048vcq&flashvars[streamerType]=auto"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>

        {!this.props.auth && (
          <div className="bx--row login-page-button">
            <a href="/appid/login">
              <Button renderIcon={IconRightArrow} className="startABOButton">
                Start ABO Trial
              </Button>
            </a>
          </div>
        )}

        <Modal
          className="IconDeleteModal"
          hasScrollingContent={false}
          iconDescription="Close"
          modalAriaLabel="Your current session has timed out. Please log in again to continue."
          modalHeading="Session Timeout"
          modalLabel=""
          onBlur={function noRefCheck() { }}
          onClick={function noRefCheck() { }}
          onFocus={function noRefCheck() { }}
          onKeyDown={function noRefCheck() { }}
          onRequestClose={this.handleClose}
          onRequestSubmit={function noRefCheck() { }}
          onSecondarySubmit={function noRefCheck() { }}
          open={this.state.showModal}
          passiveModal
          primaryButtonDisabled={false}
          primaryButtonText="Primary Button"
          secondaryButtonText="Secondary Button"
          selectorPrimaryFocus="[data-modal-primary-focus]"
          size={undefined}>
          <p className="bx--modal-content_text_timeout_modal">
            Your current session has timed out. Please log in again to continue.
          </p>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: () => dispatch(actions.checkAuth()),
    dispatch,
  };
};

//maping redux store to the component using props
//passing the auth to the component from redux store
function mapStateToProps(state) {
  //debugger;
  return {
    auth: state.auth.isAuth,
    name: state.auth.name,
    email: state.auth.email,
    id: state.auth.id,
  };
}

//connect function to map the state to props in the component
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
