import React from 'react';

import {
  Button,
  Tab,
  Tabs,
  InlineNotification,
  Accordion,
  AccordionItem,
  Modal,
} from 'carbon-components-react';

import FileUploader from './FileUploader';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OptimizedDatasetTable from './OptimizedDatasetTable';
import AvailableDatasetTable from './AvailableDatasetTable';

const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};

const margin_top_20 = {
  marginTop: '20px',
};

const margin_top_10 = {
  marginTop: '10px',
};

const accordion_style = {
  width: '100%',
  marginTop: '20px',
};

class OptimizeDataset extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {};
  }

  /*
  when the user select the files from their folder
  update the files information in the array
  */
  handleChange = files => {
    this.props.handleChangeSelectFileForUpload(files);
  };

  //delete the selected files from the list
  //when the user click the Closed icon
  //in the FileUploader component
  handleClick = filteredArray => {
    this.props.handleDelectSelectFileForUpload(filteredArray);
  };

  //deleteSelectedRow in available data set table
  deleteSelectedRow = () => {
    this.props.deleteSelectedRowAvailableDataset(
      this.props.currentSelectDatasetName
    );
  };

  ////deleteSelectedRow in optimized data set table
  deleteSelectedRowOptimizedDataset = () => {
    this.props.deleteSelectedRowOptimizedDataset(
      this.props.currentSelectOptimizedDatasetName
    );
  };

  /*
handleSubmitButton is called when the Upload File submit button is clicked
*/

  handleSubmitButton = async () => {
    this.props.validateFileUpload();
    //clear the files in the UploadFile Component
    this.child.current.clearFiles();
  };

  /*
getSelectedRow is called when a row is selected in the Available data set table
*/
  getSelectedRow = selectedRows => {
    this.props.getSelectedRowAvailableDataset(selectedRows);
  };

  getSelectedRowOptimizedDataset = selectedRows => {
    this.props.getSelectedRowOptimizedDataset(selectedRows);
  };

  //batch delete function
  //deleted the selected rows on the available data set table
  handleDeleteDataset = () => {
    this.props.handleBatchDeleteAvailableDataset();
  };

  //batch delete function
  //deleted the selected rows on the optimized data set table
  handleDeleteOptimizedDataset = () => {
    this.props.handleBatchDeleteOptimizedDataset();
  };

  showDeletedNotificationCloseButtonAvailableDataset = () => {
    this.props.showDeletedNotificationCloseButtonAvailableDataset();
  };

  showDeletedNotificationCloseButtonOptimizedDataset = () => {
    this.props.showDeletedNotificationCloseButtonOptimizedDataset();
  };

  showOptimizingNotificationCloseButton = () => {
    this.props.showOptimizingNotificationCloseButton();
  };

  showOpitimizedCompleteNotificationCloseButton = () => {
    this.props.showOpitimizedCompleteNotificationCloseButton();
  };

  showOpitimizedCompleteNotificationTimeOutCloseButton = () => {
    this.props.showOpitimizedCompleteNotificationTimeOutCloseButton();
  };

  disableCompleteUploadNotification = () => {
    this.props.disableCompleteUploadNotification();
  };

  closeModal = () => {
    this.props.closeUploadConfirmationModal();
  };

  closeDeleteModal = () => {
    this.props.closeDeleteModal();
  };

  closeDeleteModalOpitmized = () => {
    this.props.closeDeleteModalOpitmized();
  };

  render() {
    const isFileListEmpty =
      this.props.fileUploadInformation.length === 0 ? true : false;

    //const buttonKind = isFileListEmpty ? 'primary' : 'tertiary';
    const buttonKind = 'tertiary';

    let titleOptimized;

    let deleteItem = this.props.selectedSingleFileOptimizedDataset[0];

    if (deleteItem)
      titleOptimized = this.props.selectedSingleFileOptimizedDataset.join(' ');
    else titleOptimized = 'Entry ';

    const titleAvailable = this.props.selectedSingleFileAvailableDataset.join(
      ' '
    );

    const titleOptimizing =
      this.props.optimizingDatasetName + ' is being optimized';

    let titleOptimizedCompleted;

    const lastOpitmizedSuccessNumber = this.props.lastOpitmizedSuccessNumber;

    if (lastOpitmizedSuccessNumber > 0)
      titleOptimizedCompleted =
        this.props.lastOpitmizedDatasetName + ' has been optimized';
    else
      titleOptimizedCompleted =
        this.props.lastOpitmizedDatasetName + ' has not been optimized';

    let overWriteMessage;

    const duplicatedLength = this.props.uploadDuplicatedNameList.length;

    if (duplicatedLength === 1) {
      overWriteMessage =
        this.props.uploadDuplicatedNameList[0] +
        ' has been uploaded before. Are you sure you want to overwrite it?';
    } else if (duplicatedLength === 2) {
      overWriteMessage =
        this.props.uploadDuplicatedNameList[0] +
        ' and ' +
        this.props.uploadDuplicatedNameList[1] +
        ' have been uploaded before. Are you sure you want to overwrite them?';
    } else if (duplicatedLength > 2) {
      overWriteMessage =
        this.props.uploadDuplicatedNameList
          .slice(0, duplicatedLength - 1)
          .join(', ')
          .toString() +
        ' and ' +
        this.props.uploadDuplicatedNameList[duplicatedLength - 1] +
        ' have been uploaded before. Are you sure you want to overwrite them?';
    }

    return (
      <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
        <div className="bx--row instruction_section">
          <div className="bx--col-md-4 bx--col-lg-7">
            <h2 className="productive-heading-02">Upload data sets</h2>
            <p className="paragraph_styles" style={{ marginTop: '10px' }}>
              Only .XMIT.TRS files up to 20MB
            </p>

            <div className="bx--btn-set">
              <FileUploader
                buttonLabel="Add files"
                disableCompleteUploadNotification={
                  this.disableCompleteUploadNotification
                }
                showCompletedNotification={this.props.showCompletedNotification}
                showErrorNotification={this.props.showErrorNotification}
                errorMessage={this.props.errorMessage}
                fileArray={this.props.fileArray}
                filenameStatus={this.props.fileUploadStatus}
                accept={['.TRS']}
                buttonKind={buttonKind}
                numberOfFiles={this.props.numberOfFiles}
                lastUploadDatasetName={this.props.lastUploadDatasetName}
                completeUploadDatasetName={this.props.completeUploadDatasetName}
                name=""
                multiple
                onChange={this.handleChange}
                onClick={this.handleClick}
                disableOpitmizationDueToExpiry={
                  this.props.disableOpitmizationDueToExpiry
                }
                isFileListEmpty={isFileListEmpty}
                handleSubmitButton={this.handleSubmitButton}
                ref={this.child}
              />

              {/*              
              
              <Button
                kind="primary"
                onClick={this.handleSubmitButton}
                disabled={isFileListEmpty}>
                Submit
              </Button> 
              
              */}
            </div>
          </div>
          <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
            <h2 className="productive-heading-02">Instructions</h2>

            <div
              className="paragraph_styles_justify"
              style={{ marginTop: '20px' }}>
              <Accordion>
                <AccordionItem title="1. Upload data sets">
                  <div style={{ width: '100%' }}>
                    <p style={{ marginTop: '10px' }}>
                      Add the data sets to be optimized by pressing the{' '}
                      <b style={{ fontWeight: 'bold' }}>Add files </b>
                      button. A sample data set is provided for you{' '}
                      <a href="/api/download2/COMPTX.XMIT.TRS">here</a>. When
                      you are satisfied with your data set selection, click the{' '}
                      <b style={{ fontWeight: 'bold' }}>Submit </b>
                      button to upload the data sets to the Trial Cloud. Then
                      you can find the data sets on the{' '}
                      <b style={{ fontWeight: 'bold' }}>Available data sets </b>
                      tab.
                    </p>
                  </div>
                </AccordionItem>
                <AccordionItem title="2. Review and select modules for optimization">
                  <div style={{ width: '100%' }}>
                    <p style={{ marginTop: '10px' }}>
                      On the{' '}
                      <b style={{ fontWeight: 'bold' }}>Available data sets </b>
                      tab, select one data set and click the{' '}
                      <b style={{ fontWeight: 'bold' }}>Select & optimize </b>
                      button to open the data set and see the modules contained
                      within. On the data set page, select the applicable
                      hardware and software version, and uncheck any modules you
                      want to skip for optimization.
                    </p>
                  </div>
                </AccordionItem>
                <AccordionItem title="3. Optimize data sets">
                  <div style={{ width: '100%' }}>
                    <p style={{ marginTop: '10px' }}>
                      When you are fine with your module selection, click the{' '}
                      <b style={{ fontWeight: 'bold' }}>Optimize </b>
                      button. Data sets will appear on the{' '}
                      <b style={{ fontWeight: 'bold' }}>Optimized data sets </b>
                      tab during the optimization.
                    </p>
                  </div>
                </AccordionItem>
                <AccordionItem title="4. Download optimized data sets">
                  <div style={{ width: '100%' }}>
                    <p style={{ marginTop: '10px' }}>
                      On the{' '}
                      <b style={{ fontWeight: 'bold' }}>Optimized data sets </b>
                      tab, press the{' '}
                      <b style={{ fontWeight: 'bold' }}>Download data set </b>
                      button to download the optimized data sets to your
                      workstation.
                    </p>

                    <p style={{ marginTop: '10px' }}>
                      The optimized zip file contains:
                    </p>

                    <ul
                      style={{
                        listStyleType: 'disc',
                        paddingLeft: '20px',
                      }}>
                      <li>
                        A tersed data set containing the optimized program
                        module(s)
                      </li>
                      <li>The ABO optimization log file in ASCII format</li>
                      <li>The ABO scanning log file in ASCII format</li>
                    </ul>
                  </div>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>

        <div
          className="bx--row optimizer_table_section"
          style={{ marginTop: '10px' }}>
          <div className="bx--col-lg-16">
            <Tabs
              type="container"
              className="dataset_table_tab_container"
              ariaLabel="dataset tab"
              aria-label="dataset tab"
              iconDescription="show menu options"
              onKeyDown={function noRefCheck() {}}
              onSelectionChange={function noRefCheck() {}}
              role="navigation"
              tabContentClassName="tab-content"
              triggerHref="#"
              selected={this.props.datasetTableTab}>
              <Tab
                href="#"
                label="Available data sets"
                id="available-dataset-tab"
                role="presentation"
                selected={false}
                tabIndex={0}
                onClick={this.changeTab1}>
                {this.props.showDeletedNotificationAvailableDataset &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="success"
                      title={titleAvailable}
                      subtitle=" has been successfully deleted"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this.showDeletedNotificationCloseButtonAvailableDataset
                      }
                    />
                  )}

                {this.props.optimizingDatasetName && (
                  <InlineNotification
                    className="deleteSuccessNotification"
                    kind="info"
                    title={titleOptimizing}
                    subtitle=" Review its status in the 'Optimized data sets' tab"
                    iconDescription="describes the close button"
                    onCloseButtonClick={
                      this.showOptimizingNotificationCloseButton
                    }
                  />
                )}

                {this.props.showOptimizedCompletedNotification &&
                  lastOpitmizedSuccessNumber > 0 &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="success"
                      title={titleOptimizedCompleted}
                      subtitle=" Download optimized data set from the 'Optimized data sets' tab"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this.showOpitimizedCompleteNotificationCloseButton
                      }
                    />
                  )}

                {this.props.showOptimizedCompletedNotification &&
                  lastOpitmizedSuccessNumber === 0 &&
                  !this.props.optimizingTimeOut &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="warning"
                      title={titleOptimizedCompleted}
                      subtitle=" Review data set log from the 'Optimized data sets' tab"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this.showOpitimizedCompleteNotificationCloseButton
                      }
                    />
                  )}

                {this.props.showOptimizedCompletedNotification &&
                  lastOpitmizedSuccessNumber === 0 &&
                  this.props.optimizingTimeOut &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="error"
                      title={titleOptimizedCompleted}
                      subtitle=" Support has been notified and will contact you via email"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this
                          .showOpitimizedCompleteNotificationTimeOutCloseButton
                      }
                    />
                  )}

                {this.props.showOptimizedCompletedNotification &&
                  this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="error"
                      title="Your session has expired, please refresh the page and try again"
                      subtitle=""
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this
                          .showOpitimizedCompleteNotificationTimeOutCloseButton
                      }
                    />
                  )}
                <AvailableDatasetTable
                  filenames={this.props.fileArray}
                  inProgressTabFocus={this.inProgressTabFocus}
                  getSelectedNumber={this.getSelectedNumber}
                  deleteSelectedRow={this.deleteSelectedRow}
                  getSelectedRow={this.getSelectedRow}
                  handleDeleteDataset={this.handleDeleteDataset}
                  showSelectModuleTableButton={
                    this.props.showSelectModuleTableButton
                  }
                  showDeleteModalTrigger={this.props.showDeleteModalTrigger}
                />
              </Tab>
              <Tab
                id="opitmized-dataset-tab"
                role="presentation"
                label="Optimized data sets"
                className="optimizedDatasetTab"
                selected={false}
                tabIndex={0}
                onClick={this.changeTab2}>
                {this.props.showDeletedNotificationOptimizedDataset &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="success"
                      title={titleOptimized}
                      subtitle=" opitmized data set has been successfully deleted"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this.showDeletedNotificationCloseButtonOptimizedDataset
                      }
                    />
                  )}

                {this.props.optimizingDatasetName && (
                  <InlineNotification
                    className="deleteSuccessNotification"
                    kind="info"
                    title={titleOptimizing}
                    subtitle=" Review its status in the 'Optimized data sets' tab"
                    iconDescription="describes the close button"
                    onCloseButtonClick={
                      this.showOptimizingNotificationCloseButton
                    }
                  />
                )}

                {this.props.showOptimizedCompletedNotification &&
                  lastOpitmizedSuccessNumber > 0 &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="success"
                      title={titleOptimizedCompleted}
                      subtitle=" Download optimized data set from the 'Optimized data sets' tab"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this.showOpitimizedCompleteNotificationCloseButton
                      }
                    />
                  )}

                {this.props.showOptimizedCompletedNotification &&
                  lastOpitmizedSuccessNumber === 0 &&
                  !this.props.optimizingTimeOut &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="warning"
                      title={titleOptimizedCompleted}
                      subtitle=" Review data set log from the 'Optimized data sets' tab"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this.showOpitimizedCompleteNotificationCloseButton
                      }
                    />
                  )}

                {this.props.showOptimizedCompletedNotification &&
                  lastOpitmizedSuccessNumber === 0 &&
                  this.props.optimizingTimeOut &&
                  !this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="error"
                      title={titleOptimizedCompleted}
                      subtitle=" Support has been notified and will contact you via email"
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this
                          .showOpitimizedCompleteNotificationTimeOutCloseButton
                      }
                    />
                  )}

                {this.props.showOptimizedCompletedNotification &&
                  this.props.timeOutSession && (
                    <InlineNotification
                      className="deleteSuccessNotification"
                      kind="error"
                      title="Your session has expired, please refresh the page and try again"
                      subtitle=""
                      iconDescription="describes the close button"
                      onCloseButtonClick={
                        this
                          .showOpitimizedCompleteNotificationTimeOutCloseButton
                      }
                    />
                  )}
                <OptimizedDatasetTable
                  completedData={this.props.fileArrayOptimized}
                  deleteSelectedRow={this.deleteSelectedRowOptimizedDataset}
                  handleDeleteOptimizedDataset={
                    this.handleDeleteOptimizedDataset
                  }
                  getSelectedRowOptimizedDataset={
                    this.getSelectedRowOptimizedDataset
                  }
                  bucketName={this.props.bucketName}
                  showDeleteModalTriggerOptimizedDataset={
                    this.props.showDeleteModalTriggerOptimizedDataset
                  }
                />
              </Tab>
            </Tabs>
          </div>
        </div>

        <Modal
          className="IconDeleteModal"
          hasScrollingContent={false}
          focusTrap={false}
          iconDescription="Close the modal"
          modalAriaLabel="A label to be read by screen readers on the modal root node"
          modalHeading="Confirm Overwrite"
          onBlur={function noRefCheck() {}}
          onClick={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onRequestClose={this.closeModal}
          onRequestSubmit={this.props.handleUploadFileSubmitButton}
          onSecondarySubmit={this.closeModal}
          open={this.props.uploadDuplicatedName}
          passiveModal={false}
          primaryButtonDisabled={false}
          primaryButtonText="Continue"
          secondaryButtonText="Cancel"
          selectorPrimaryFocus="[data-modal-primary-focus]">
          <p className="bx--modal-content__text">{overWriteMessage}</p>
        </Modal>

        <Modal
          className="IconDeleteModal"
          hasScrollingContent={false}
          focusTrap={false}
          iconDescription="Close the modal"
          modalAriaLabel="A label to be read by screen readers on the modal root node"
          modalHeading="Delete this data set?"
          onBlur={function noRefCheck() {}}
          onClick={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onRequestClose={this.closeDeleteModal}
          onRequestSubmit={this.deleteSelectedRow}
          onSecondarySubmit={this.closeDeleteModal}
          open={this.props.showDeleteModal}
          passiveModal={false}
          danger
          primaryButtonDisabled={false}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          selectorPrimaryFocus="[data-modal-primary-focus]">
          <p className="bx--modal-content__text">
            This action cannot be undone. Are you sure you want to delete{' '}
            {this.props.currentSelectDatasetName}
          </p>
        </Modal>

        <Modal
          className="IconDeleteModal"
          hasScrollingContent={false}
          focusTrap={false}
          iconDescription="Close the modal"
          modalAriaLabel="A label to be read by screen readers on the modal root node"
          modalHeading="Delete this data set?"
          onBlur={function noRefCheck() {}}
          onClick={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onRequestClose={this.closeDeleteModalOpitmized}
          onRequestSubmit={this.deleteSelectedRowOptimizedDataset}
          onSecondarySubmit={this.closeDeleteModalOpitmized}
          open={this.props.showDeleteModalOptimizedDataset}
          passiveModal={false}
          danger
          primaryButtonDisabled={false}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          selectorPrimaryFocus="[data-modal-primary-focus]">
          <p className="bx--modal-content__text">
            This action cannot be undone. Are you sure you want to delete{' '}
            {this.props.currentSelectOptimizedDatasetName.name}
          </p>
        </Modal>
      </div>
    );
  }
}

//mapping the redux store data to the componenet state
const mapStateToProps = state => {
  //debugger;
  return {
    datasets: state.datasets.data[0],
    optimizedDatasets: state.datasets.data[1],
    token: state.auth.token,
    id: state.auth.id,
    name: state.auth.name,
    acceptUserAgreement: state.datasets.data[2],
    fileArrayHistory: state.datasets.data[3],
    lastOpitmizedDatasetName: state.datasets.data[4],
    lastOpitmizedSuccessNumber: state.datasets.data[5],
    startTrialDate: state.datasets.data[6],
    expiryDateFormat: state.datasets.data[7],
    optimizingDatasetName: state.datasets.data[8],
    notifications: state.datasets.data[9],
    maintenance: state.datasets.data[10],
  };
};

export default withRouter(connect(mapStateToProps)(OptimizeDataset));
